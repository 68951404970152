import React from 'react';
import ReactDOM from 'react-dom';
import initI18n from '../src/i18n';
import App from './App';
import Analytics from './analytics';
import initSentry from './utils/sentry';
import initWidget from './utils/widget';

function renderApp() {
  initWidget();
  initSentry();
  initI18n();
  Analytics.init();
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root'),
  );
}

renderApp();
