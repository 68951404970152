import axios from 'axios';
import Session from '../models/session';
import User from '../models/user';
import UserResponse from '../models/user-response';

const getUser = async (session: Session): Promise<User> => {
  // WART: remove axios
  return await axios({
    url: `${process.env.REACT_APP_KITCHEN_DISPLAY_URL}/api/userInfo`,
    method: 'GET',
    headers: {
      'x-user-id': session.userId,
      'x-auth-token': session.loginToken,
    },
  }).then<User>((response) => {
    const data: UserResponse = response.data;

    const user: User = {
      _id: data._id,
      profile: data.profile,
      rolName: data.rol,
      profileImage: data.profileImage,
    };

    return user;
  });
};

const UserService = {
  getUser,
};
export default UserService;
