import { createSlice, PayloadAction } from '@reduxjs/toolkit';
type LoadState = {
  [item: string]: { completed: boolean };
};

interface DataLoadState {
  state: LoadState;
  loading: boolean;
  completed: boolean;
}

const initialState: DataLoadState = {
  state: {},
  loading: false,
  completed: true,
};

const dataLoad = createSlice({
  name: 'dataLoad',
  initialState,
  reducers: {
    startDataLoad: (state, items: PayloadAction<string[]>) => {
      const initialState: LoadState = {};
      items.payload.forEach((item) => {
        initialState[item] = { completed: false };
      });
      state.state = initialState;
    },
    completeItem: (state, item: PayloadAction<string>) => {
      state.state[item.payload].completed = true;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => {
        const startDataLoadAction = 'dataLoad/startDataLoad';
        const completeItemAction = 'dataLoad/completeItem';
        return action.type === startDataLoadAction || action.type === completeItemAction;
      },
      (state) => {
      const allCompleted = Object.keys(state.state).every((item) => state.state[item].completed);
      state.loading = !allCompleted;
      state.completed = allCompleted;
      },
    );
  },
});

export const { startDataLoad, completeItem } = dataLoad.actions;
export default dataLoad.reducer;
