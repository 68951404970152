import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Brands {
    brands: string[];
  }

const initialState: Brands = {
    brands: [],
  };
  
  const brandsSlice = createSlice({
    name: 'brandsSlice',
    initialState,
    reducers: {
      setBrands: (state, brands: PayloadAction<string[]>) => {
        state.brands = brands.payload;
      },
    },
  });
  
  export const { setBrands } = brandsSlice.actions;
  export default brandsSlice.reducer;