import axios from 'axios';
import { Protocol } from '../models/protocol';

const findByKitchen = async (
  kitchenId: string,
  page = 0,
  resultsPerPage = 200,
): Promise<Protocol[]> => {
  const url = `${process.env.REACT_APP_API_URL}/api/v1/menu/manager/kitchen/${kitchenId}/protocols?page=${page}&resultsPerPage=${resultsPerPage}`;
  return axios({
    method: 'GET',
    url,
  }).then<Protocol[]>((response) => response.data);
};

const updateSingleProtocol = async (protocol: Protocol): Promise<Protocol> => {
  const url = `${process.env.REACT_APP_API_URL}/api/v1/menu/manager/protocol/availability`;
  axios({
    method: 'POST',
    url,
    headers: {
      'Content-Type': 'application/json',
    },
    data: JSON.stringify({
      kitchenId: protocol.kitchenId,
      name: protocol.name,
      skus: protocol.skus,
      availability: protocol.active,
      user: protocol.user,
    }),
  }).then<string>((response) => response.data);
  return protocol;
};

const ProtocolService = { findByKitchen, updateSingleProtocol };

export default ProtocolService;
