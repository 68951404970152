import { Protocol } from '../../../../models/protocol';
import ProtocolService from '../../../../services/protocol.service';
import { CanGetProtocolsByKitchen } from '../../interfaces/protocol/CanGetProtocolsByKitchen';

export class ProtocolHttpRepository implements CanGetProtocolsByKitchen {
  async getByKitchen(kitchenId: string): Promise<Protocol[]> {
    let allProtocols: Protocol[] = [];
    let currentPage = 0;
    let moreData = true;

    while (moreData) {
      try {
        const protocols = await ProtocolService.findByKitchen(kitchenId, currentPage);

        if (protocols.length > 0) {
          allProtocols = allProtocols.concat(protocols);
          currentPage++;
        } else {
          moreData = false;
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        moreData = false;
      }
    }

    return allProtocols;
  }
}
