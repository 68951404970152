export enum StatusAvailability {
  Available = 'Available',
  Unavailable = 'Unavailable',
  Null = '',
}

export const stringToStatusAvailability = (value: string): StatusAvailability => {
  if (value === StatusAvailability.Available) {
    return StatusAvailability.Available;
  }

  if (value === StatusAvailability.Unavailable) {
    return StatusAvailability.Unavailable;
  }

  return StatusAvailability.Null;
};

export enum OperationTypeCRUD {
  Create,
  Read,
  Update,
  Delete
}

