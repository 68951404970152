import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface BrandsSelected {
  brands: string[];
}

const initialState: BrandsSelected = {
  brands: [],
};

const brandsSelectedSlice = createSlice({
  name: 'brandsSelected',
  initialState,
  reducers: {
    setBrandsSelected: (state, brands: PayloadAction<string[]>) => {
      state.brands = brands.payload;
    },
  },
});

export const { setBrandsSelected } = brandsSelectedSlice.actions;
export default brandsSelectedSlice.reducer;

export interface Brands {
  brands: string[];
}
