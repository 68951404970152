import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { MenuConfigState, MenuConfigUpdate } from '../../../models/menu/menu';
import { Country } from '../../../config/app';

const initialState: MenuConfigState = {
  country: localStorage.getItem('country') as Country,
  copiedMenuId: '',
  selectedCities: [],
  selectedPlatforms: [],
  selectedBrand: '',
  selectedStores: [],
  selectedKitchens: [],
};

const menuConfigSlice = createSlice({
  name: 'menu-config-state',
  initialState,
  reducers: {
    setCountry: (state, action: PayloadAction<string>) => {
      const countryValue = action.payload as Country;
      if (Object.values(Country).includes(countryValue)) {
        state.country = countryValue;
      }
    },
    setCopiedMenuId: (state, action: PayloadAction<string>) => {
      state.copiedMenuId = action.payload;
    },
    setMenuConfig: (state, action: PayloadAction<MenuConfigUpdate>) => {
      const { selectedCities, selectedPlatforms, selectedBrand, selectedStores, selectedKitchens } =
        action.payload;
      state.selectedCities = selectedCities;
      state.selectedPlatforms = selectedPlatforms;
      state.selectedBrand = selectedBrand;
      state.selectedStores = selectedStores;
      state.selectedKitchens = selectedKitchens;
    },
    resetMenuConfigState: () => {
      return initialState;
    },
    initializEditMenuConfigState: (state, action: PayloadAction<MenuConfigUpdate>) => {
      const { selectedCities, selectedPlatforms, selectedBrand, selectedStores, selectedKitchens } =
        action.payload;
      state.selectedCities = selectedCities;
      state.selectedPlatforms = selectedPlatforms;
      state.selectedBrand = selectedBrand;
      state.selectedStores = selectedStores;
      state.selectedKitchens = selectedKitchens;
    },
  },
});

export const {
  setCountry,
  setCopiedMenuId,
  setMenuConfig,
  resetMenuConfigState,
  initializEditMenuConfigState,
} = menuConfigSlice.actions;

export default menuConfigSlice.reducer;

export const selectMenuConfigState = (state: RootState): MenuConfigState => state.menuConfig;
