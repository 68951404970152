import { createAsyncThunk } from '@reduxjs/toolkit';
import { ProductHtpRepository } from '../../../core/OnAndOff/repositories/http/product';
import { CanGetAllProductByKitchenUseCase } from '../../../core/OnAndOff/useCases/product/CanGetAllProductByKitchen.usecase';
import SupplyService from '../../../services/supply.service';
import { ProtocolHttpRepository } from '../../../core/OnAndOff/repositories/http/protocol';
import { CanGetProtocolsByKitchenUseCase } from '../../../core/OnAndOff/useCases/protocol/CanGetProtocolsByKitchen.usecase';
import { Supply } from '../../../models/supply';
import {
  ProductManager,
  ProductSearch,
  ProductsSupply,
  StoreProduct,
} from '../../../models/product';
import ProductService from '../../../services/product.service';
import { Protocol } from '../../../models/protocol';
import ProtocolService from '../../../services/protocol.service';
import { SupplyHttpRepository } from '../../../core/OnAndOff/repositories/http/supply';
import { CanGetSupplyByKitchenUseCase } from '../../../core/OnAndOff/useCases/supply/CanGetSupplyByKitchen.usecase';

export const getProductsState = createAsyncThunk(
  'onAndOffState/getProductState',
  async (kitchenId: string) => {
    const repository = new ProductHtpRepository();
    const product = new CanGetAllProductByKitchenUseCase(repository);
    return await product.execute(kitchenId);
  },
);

export const getSuppliesState = createAsyncThunk(
  'onAndOffState/getSuppliesState',
  async (kitchenId: string) => {
    const repository = new SupplyHttpRepository();
    const supplies = new CanGetSupplyByKitchenUseCase(repository);
    return await supplies.execute(kitchenId);
  },
);
export const getProtocolsState = createAsyncThunk(
  'onAndOffState/getProtocolsState',
  async (kitchenId: string) => {
    const repository = new ProtocolHttpRepository();
    const protocol = new CanGetProtocolsByKitchenUseCase(repository);
    return await protocol.execute(kitchenId);
  },
);

export const updateSupplySingle = createAsyncThunk(
  'onAndOffState/updateSupplySingle',
  async (supply: Supply): Promise<Supply> => {
    return await SupplyService.updateSingleSupply(supply);
  },
);

export const updateProductSingle = createAsyncThunk(
  'onAndOffState/updateProductSingle',
  async (product: ProductManager): Promise<ProductManager> => {
    return await ProductService.updateSingleProduct(product);
  },
);

export const updateProtocolSingle = createAsyncThunk(
  'onAndOffState/updateProtocolSingle',
  async (protocol: Protocol): Promise<Protocol> => {
    return await ProtocolService.updateSingleProtocol(protocol);
  },
);

export const searchProductByKitchen = createAsyncThunk(
  'onAndOffState/searchProductByKitchen',
  async (kitchenId: string): Promise<ProductSearch[]> =>
    await ProductService.searchProductByKitchen(kitchenId),
);

export const getStoresBySkuAndKitchen = createAsyncThunk(
  'onAndOffState/getStoresBySkuAndKitchen',
  async ({ kitchen, sku }: { kitchen: string; sku: string }): Promise<StoreProduct> =>
    await ProductService.getStoresByKitchenAndSku(kitchen, sku),
);

export const getProductsBySkuAndKitchen = createAsyncThunk(
  'onAndOffState/getProductsBySkuAndKitchen',
  async ({ kitchen, sku }: { kitchen: string; sku: string }): Promise<ProductsSupply> => {
    return await ProductService.getProductsByKitchenAndSku(kitchen, sku);
  },
);
