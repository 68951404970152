import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ClassificationSelected {
  classification: string[];
}

const initialState: ClassificationSelected = {
    classification: [],
};

const classificationSelectedSlice = createSlice({
  name: 'classificationSelected',
  initialState,
  reducers: {
    setClassificationSelected: (state, classification: PayloadAction<string[]>) => {
      state.classification = classification.payload;
    },
  },
});

export const { setClassificationSelected } = classificationSelectedSlice.actions;
export default classificationSelectedSlice.reducer;