import React from 'react';
import Box from '@mui/material/Box';

import Spinner from '../Spinner';

const LockScreen = (): React.ReactElement => (
  <Box
    sx={{
      top: 0,
      position: 'fixed',
      zIndex: 10000,
      height: '100vh',
      width: '100vw',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#00000042',
    }}
  >
    <Spinner></Spinner>
  </Box>
);

export default LockScreen;
