import { configureStore } from '@reduxjs/toolkit';
import appLayoutSlice from '../components/AppLayout/appLayout.slice';
import headerSlice from '../components/AppLayout/components/Header/header.slice';
import sessionSlice from '../routes/components/Session/session.slice';
import OnAndOffSlice from './slices/OnAndOffStateSlice';
import dataLoadSlice from './slices/DataLoadStateSlice/dataLoad.slice';
import ButtonState from './slices/OnAndOffStateSlice/buttonState';
import BrandsSelected from './slices/OnAndOffStateSlice/brandsSelected';
import ClassificationSelected from './slices/OnAndOffStateSlice/classificationsSelected';
import Brands from './slices/OnAndOffStateSlice/brands';
import Classifications from './slices/OnAndOffStateSlice/classification';
import CreateStateSlice from './slices/MenuManagerStateSlice/CreateMenuStateSlice';
import MenuConfigSlice from './slices/MenuManagerStateSlice/MenuConfigSlice';

const store = configureStore({
  reducer: {
    appLayout: appLayoutSlice,
    header: headerSlice,
    session: sessionSlice,
    onAndOff: OnAndOffSlice,
    dataLoad: dataLoadSlice,
    buttonState: ButtonState,
    brandsSelected: BrandsSelected,
    classificationSelected: ClassificationSelected,
    brands: Brands,
    classifications: Classifications,
    createMenu: CreateStateSlice,
    menuConfig: MenuConfigSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
