import React from 'react';
import { useLocation } from 'react-router';
import Spinner from '../../../components/Spinner';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import SessionService from './services/session.service';
import UserService from './services/user.service';
import { setSession, setUser } from './session.slice';

interface SessionProps {
  children: React.ReactNode;
}

const Session = (props: SessionProps) => {
  const [loading, setLoading] = React.useState(true);
  const user = useAppSelector((state) => state.session.user);
  const dispatch = useAppDispatch();
  const location = useLocation();

  const redirectToLogin = React.useCallback(() => {
    const kdsUrl = process.env.REACT_APP_KITCHEN_DISPLAY_URL;
    const menuManagerUrl = process.env.REACT_APP_URL;
    window.location.href = `${kdsUrl}/login?redirect=${menuManagerUrl}${location.pathname}`;
  }, [location.pathname]);

  const startSession = React.useCallback(async () => {
    const session = SessionService.getSession();
    if (session) {
      const newUser = await UserService.getUser(session).catch((e) => {
        const unauthorizedStatus = 401;
        if (e.response?.status === unauthorizedStatus) {
          SessionService.removeSession();
          redirectToLogin();
        }
        throw e;
      });
      dispatch(setSession(session));
      dispatch(setUser(newUser));
    } else {
      redirectToLogin();
    }
  }, [dispatch, redirectToLogin]);

  React.useEffect(() => {
    if (!user) {
      startSession().then(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [startSession, user]);

  return (
    <>
      {loading && <Spinner></Spinner>}
      {!loading && user && props.children}
    </>
  );
};

export default Session;
