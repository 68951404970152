import amplitude from 'amplitude-js';
import store from '../store';

export enum AmplitudeEvent {
  VISIT = 'recipe-book visited',
  VISIT_AS_ADMIN = 'recipe-book visited as admin',
  RECIPE_OPENED = 'recipe opened',
  RECIPE_OPENED_AS_ADMIN = 'recipe opened as admin',
  CATEGORIZATION_OPENED = 'categorization opened',
  SEARCH_IN_RECIPE_BOOK = 'search in recipe book',
  SEARCH_IN_ON_AND_OFF = 'search in on and off',
  CHANGES_IN_RECIPE = 'changes in recipe',
  VISIT_ON_AND_OFF_AS_ADMIN = 'on and off visited as admin',
  VISIT_ON_AND_OFF = 'on and off visited',
  DRAWER_STORES_OPENED = 'drawer stores opened',
  FILTERS = 'filters',
  FILTERS_IN_RECIPE_BOOK = 'filters in recipe book',
  DOWNLOAD_RECIPE = 'download recipe',
  PAGE_CHANGED_IN_RECIPE_BOOK = 'page changed in recipe book',
  ON_OFF_TAB = 'on and off tab',
  ON_OFF_BRANDS_FILTER = 'on and off brands tab filter',
  CREATE_NEW_MENU = 'create new menu',
  EDIT_MENU = 'edit menu',
  COPY_MENU = 'copy menu',
  VIEW_STORES_FROM_MENU = 'view stores from menu',
  SCHEDULE_DRAWER = 'schedule drawer',
  DRAG_AND_DROP_MODIFIER_GROUPS_DRAWER = 'drag and drop modifier groups drawer',
  DRAG_AND_DROP_MODIFIERS_DRAWER = 'drag and drop modifiers drawer',
  BUTTON_EDIT_MODIFIER_GROUP = 'button edit modifier group',
  BUTTON_SAVE_MODIFIER_AS_TEMPLATE = 'button save modifier as template',
  BUTTON_SAVE_MODIFIER_GROUP_AS_TEMPLATE = 'button save modifier group as template',
  SEARCH_MENU = 'search menu',
  SEARCH_MODIFIER_GROUP = 'search modifier group',
  SEARCH_MODIFIER = 'search modifier',
  SEARCH_MENU_ITEM = 'search menu item',
  EDIT_MODIFIER_GROUP = 'edit modifier group',
  EDIT_MODIFIER = 'edit modifier',
  BUTTON_BACK = 'button back',
  CREATE_MODIFIER_GROUP = 'create modifier group',
  CREATE_MODIFIER = 'create modifier',
  DRAG_AND_DROP_INDEX = 'drag and drop index',
}

const init = (): void => {
  amplitude.getInstance().init(`${process.env.REACT_APP_AMPLITUDE_APY_KEY}`);
};

const logEvent = (event: AmplitudeEvent, eventProperties?: Record<string, unknown>): void => {
  amplitude.getInstance().logEvent(event, eventProperties);
};

const logEventWithUser = (
  event: AmplitudeEvent,
  eventProperties: Record<string, unknown> = {},
): void => {
  const state = store.getState();
  const user = state.session.user;

  const userProperties = {
    userName: user?.profile?.name,
    userRoll: user?.rolName,
    userCountry: user?.profile?.country,
  };

  const properties = {
    ...userProperties,
    ...eventProperties,
  };

  amplitude.getInstance().logEvent(event, properties);
};

const Analytics = { init, logEvent, logEventWithUser };

export default Analytics;
