import { ProductManager, StoreProduct } from '../../../../models/product';
import ProductService from '../../../../services/product.service';
import { CanGetAllProductByKitchen } from '../../interfaces/product/CanGetAllProductByKitchen';

export class ProductHtpRepository implements CanGetAllProductByKitchen {
  getStoresByProduct(sku: string): Promise<StoreProduct[]> {
    throw new Error('Method not implemented.');
  }
  async getByKitchen(kitchenId: string): Promise<ProductManager[]> {
    let allProducts: ProductManager[] = [];
    let currentPage = 0;
    let moreData = true;

    while (moreData) {
      try {
        const products = await ProductService.findByKitchen(kitchenId, currentPage, 200);
        if (products.length > 0) {
          allProducts = allProducts.concat(products);
          currentPage++;
        } else {
          moreData = false;
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        moreData = false;
      }
    }

    return allProducts;
  }
}
