import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AppLayoutState {
  showMenu: boolean;
}

const initialState: AppLayoutState = {
  showMenu: false,
};

const appLayout = createSlice({
  name: 'appLayout',
  initialState,
  reducers: {
    setShowMenu: (state, showMenu: PayloadAction<boolean>) => {
      state.showMenu = showMenu.payload;
    },
  },
});

export const { setShowMenu } = appLayout.actions;
export default appLayout.reducer;
