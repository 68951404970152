import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Classification {
    classifications: string[];
  }

const initialState: Classification = {
    classifications: [],
  };
  
  const classificationSlice = createSlice({
    name: 'classificationSlice',
    initialState,
    reducers: {
      setClassifications: (state, classifications: PayloadAction<string[]>) => {
        state.classifications = classifications.payload;
      },
    },
  });
  
  export const { setClassifications } = classificationSlice.actions;
  export default classificationSlice.reducer;