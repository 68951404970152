import { Supply } from '../../../../models/supply';
import SupplyService from '../../../../services/supply.service';
import { CanGetSuppliesByKitchen } from '../../interfaces/supply/CanGetSuppliesByKitchen';

export class SupplyHttpRepository implements CanGetSuppliesByKitchen {
  async getByKitchen(kitchenId: string): Promise<Supply[]> {
    let allSupplies: Supply[] = [];
    let currentPage = 0;
    let moreData = true;

    while (moreData) {
      try {
        const supplies = await SupplyService.findByKitchen(kitchenId, currentPage);
        if (supplies.length > 0) {
          allSupplies = allSupplies.concat(supplies);
          currentPage++;
        } else {
          moreData = false;
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        moreData = false;
      }
    }

    return allSupplies;
  }
}
