import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ButtonState {
  state: boolean;
}

const initialState: ButtonState = {
  state: false,
};

const buttonStateSlice = createSlice({
  name: 'buttonState',
  initialState,
  reducers: {
    setButtonState: (state, buttonState: PayloadAction<boolean>) => {
      state.state = buttonState.payload;
    },
  },
});

export const { setButtonState } = buttonStateSlice.actions;
export default buttonStateSlice.reducer;
