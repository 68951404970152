import dayjsUtil from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import calendar from 'dayjs/plugin/calendar';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/es';
import 'dayjs/locale/pt';
import 'dayjs/locale/en';

dayjsUtil.extend(calendar);
dayjsUtil.extend(relativeTime);
dayjsUtil.extend(utc);
dayjsUtil.extend(timezone);

export const localizeDayjs = (locale: string) => dayjsUtil.locale(locale);

export const timeAgo = (date: string) => {
  dayjsUtil().calendar();
  const localDate = dayjsUtil(date).calendar(null, {
    sameDay: '[Hoy a las] h:mm a',
    nextDay: '[Mañana a las] h:mm a',
    nextWeek: 'dddd',
    lastDay: '[Ayer a las] h:mm a',
    lastWeek: '[El] dddd [a las] h:mm a',
    sameElse: '[El] DD/MM/YYYY [a las] h:mm a',
  });
  return localDate;
};

export default dayjsUtil;
