import React from 'react';
import { KOSTopbar } from '@foodology-co/alejandria';

import { setShowMenu } from '../../appLayout.slice';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { Env, ENVIRONMENT } from '../../../../config/app';
import SessionService from '../../../../../src/routes/components/Session/services/session.service';

const Header = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const showMenu = useAppSelector((state) => state.appLayout.showMenu);
  const title = useAppSelector((state) => state.header.title);
  const session = useAppSelector((state) => state.session.user);
  const isProduction = ENVIRONMENT === Env.PRODUCTION;

  const handleToggleMenu = React.useCallback(() => {
    dispatch(setShowMenu(!showMenu));
  }, [dispatch, showMenu]);

  const handleLogoutClick = () => {
    SessionService.removeSession();
    window.location.href = `${process.env.REACT_APP_KITCHEN_DISPLAY_URL}/login`;
  };

  return (
    <KOSTopbar
      title={title}
      isDevelopment={!isProduction}
      openMenu={showMenu}
      onOpenMenuClick={handleToggleMenu}
      username={session?.profile?.name}
      avatarUrl={session?.profileImage}
      onLogoutClick={handleLogoutClick}
    />
  );
};

export default Header;
