import { ProductManager, Reason, ReasonType } from '../../../models/product';
import { StatusAvailability } from '../../../models/types';

export const getReasonValue = (item: ProductManager): StatusAvailability | null => {
  const reason = getReason(item.reasons);

  if (reason === StatusAvailability.Null) {
    return null;
  }

  return reason;
};

export const getReason = (reasons: Array<Reason>): StatusAvailability => {
  if (reasons.some((reason) => reason.type === ReasonType.PRIORITY)) {
    const index = reasons.findIndex((reason) => reason.type === ReasonType.PRIORITY);
    return reasons[index].reasons[0] as StatusAvailability;
  }

  return StatusAvailability.Null;
};
