import {
  Ingredient,
  LifeCycle,
  LifeCyclePayload,
  OtherCharacteristics,
  PreparationInstructions,
  PreparationInstructionsPayload,
  SensoryCharacteristics,
  UnitPerPackage,
} from './ingredient';
import { Discarded } from './supplyDetails';

export interface ProductProperties {
  showAsCombo?: boolean;
  openRecipeOnStation?: boolean;
  hidden?: boolean;
  recipeVisibleOnPass?: boolean;
  recipeInPrintedOrder?: boolean;
  isTopping?: boolean;
  isVariableCombo?: boolean;
  isCombo?: boolean;
}

export enum TaskCode {
  POST_IN_BC = 'POST_IN_BC',
  CREATE_EXTERNAL_SKU = 'CREATE_EXTERNAL_SKU',
}

export enum TaskState {
  TO_DO = 'TO_DO',
  DOING = 'DOING',
  DONE = 'DONE',
  ERROR = 'ERROR',
}

enum TriggerOption {
  BEFORE_CREATE = 'BEFORE_CREATE',
  BEFORE_UPDATE = 'BEFORE_UPDATE',
  BEFORE_DISSABLE = 'BEFORE_DISSABLE',
  AFTER_CREATE = 'AFTER_CREATE',
  AFTER_UPDATE = 'AFTER_UPDATE',
  AFTER_DISSABLE = 'AFTER_DISSABLE',
}

interface Task {
  code: TaskCode;
  state: TaskState;
  description: string;
  dependedBy: TaskCode[];
  startedAt?: Date;
  finishedAt?: Date;
  triggerOn: TriggerOption[];
}

export interface UsefulLife {
  timeUnit?: TimeUnit;
  value?: number;
}

export enum TimeUnit {
  HOUR = 'HOUR',
  DAY = 'DAY',
  WEEK = 'WEEK',
}

export interface Product {
  _id?: string;
  sku: string;
  name: string;
  unit: string;
  group: string;
  category: string;
  station: string;
  country: string;
  active: boolean;
  location: string | null;
  ingredients: Ingredient[];
  preparation: string[];
  lastUpdatedAt: Date;
  assembledIn: string;
  assembledStation: string | null;
  accountingRecord?: string;
  imageFile?: File;
  image?: string;
  type?: string;
  ivaMajorValue?: string;
  taxGroupCode?: string;
  overReceiptCode?: string;
  storage?: string;
  ncmCode?: string;
  productionPerformance?: number;
  user?: {
    name: string;
    id: string;
  };
  properties: ProductProperties;
  inventoryGroup: string;
  disabled: boolean;
  tasks?: Task[];
  published?: boolean;
  avoidNegativeInventory?: boolean;
  accountingGroupIVA?: string;
  productionDetailRaw?: ProductionDetail;
  productionDetail?: ProductionDetailPayload; // Refactor use only this
  ultraprocessedTax?: string;
}

export interface ProductionDetail {
  country?: string;
  sku?: string;
  preparationInstructions?: PreparationInstructions[];
  lifeCycles?: LifeCycle[];
  assembledStation?: string | null;
  sensoryCharacteristics?: SensoryCharacteristics;
  unitPerPackage?: UnitPerPackage;
  otherCharacteristics?: OtherCharacteristics;
  organoleptic?: SensoryCharacteristics;
  vacuumPacking?: number;
  packageCharacteristics?: OtherCharacteristics;
  shelfLife?: LifeCyclePayload[];
}

export interface ProductionDetailPayload {
  // Refactor update mandatory backend fields
  country?: string;
  sku?: string;
  assembledStation?: string | null;
  vacuumPacking?: number;
  preparationInstructions?: PreparationInstructionsPayload[] | null;
  shelfLife?: LifeCyclePayload[] | null;
  organoleptic?: SensoryCharacteristics | null;
  unitPerPackage?: UnitPerPackage | null;
  packageCharacteristics?: OtherCharacteristics | null;
  supplyDetail?: Discarded;
}

export interface ProductManager {
  _id: Id;
  kitchenId: string;
  sku: string;
  name: string;
  category: string;
  lastUpdate: string;
  available: boolean;
  availableByStoreId?: AvailableByStoreId;
  storeIds: string[];
  reasons: Reason[];
  theoreticalInventory: string;
  user?: {
    name: string;
    id: string;
  };
  menuStamp?: Array<MenuStamp>;
}

export interface MenuStamp {
  brandId: string;
  platform: string;
  storeId: string;
  name: string;
  menuId: string;
}

interface Id {
  timestamp: number;
  date: string;
}

interface AvailableByStoreId {
  available: boolean;
  storeIds: string[];
}

export interface Reason {
  type: ReasonType;
  reasons: string[];
}

export enum ReasonType {
  PROTOCOL = 'PROTOCOL',
  STOCK_OUT = 'STOCK_OUT',
  PRIORITY = 'PRIORITY',
  LAST_MODIFIER_COMBO = 'LAST_MODIFIER_COMBO',
}

export interface ProductSearch {
  sku: string;
  name: string;
}

export interface StoreProduct {
  kitchenId: string;
  stores: StoreRelated[];
}

export interface ProductsSupply {
  kitchenId: string;
  products: ProductRelated[];
}

interface ProductRelated {
  sku: string;
  kitchenid: string;
  menuStamp: {
    product: string;
    storeId: string;
    menuId: string;
    available: boolean;
    reasons: Reason[];
    name: string;
  };
}

export enum Platform {
  RAPPI = 'rappi',
  UBER = 'ubereats',
  DIDI = 'didi',
  JUSTO = 'justo',
  IFOOD = 'ifood',
  NEEMO = 'neemo',
  FOODOLOGYPOS = 'foodologypos',
}
interface StoreRelated {
  universalId: string;
  enabled: boolean;
  platform: Platform;
  kitchenId: string;
  country: string;
  city: string;
  brandId: string;
  schedule: Schedule;
  justoToken?: string;
  platformConfig: PlatformConfig;
  reasonState: string;
  warehouseId: string;
  permantlyDisabled: boolean;
}

interface PlatformConfig {
  isMarketPlace: boolean;
  isParentStore: boolean;
}

interface Schedule {
  week: Week;
  timeZone: string;
}

interface Week {
  monday: string[];
  tuesday: string[];
  wednesday: string[];
  thursday: string[];
  friday: string[];
  saturday: string[];
  sunday: string[];
}

export interface FinishedProduct {
  sku: string;
  name: string;
  country: string;
  category: string;
}

export interface ProductQuery {
  country: string;
  name: string | null;
  sku: string | null;
  group: string[];
  disabled: boolean;
}

export interface ProductQueryResponse {
  products: FinishedProduct[];
}

export type ProductStore = Record<Platform, Record<string, MenuStamp[]>>;

export interface ProductSelection {
  product: Product;
  factor?: number;
  difference?: number;
  spoonColor?: string;
  kdsQuantity?: number;
}

export const CommonSpoonColors: string[] = [
  // WARNING: Max length 10 characters (Bussiness Central)
  'ㅤ',
  'AMARILLO',
  'ROJO',
  'NARANJA',
  'VERDE',
  'AZUL',
  'BEIGE',
  'MORADO',
  'AMARILL_SC',
  'MORADO_SC',
  'ROD',
  'UND',
  'PLATA_SC',
];
export const SpoonColors: string[] = [
  ...CommonSpoonColors,
  'TIRA',
  'AROS',
  'POR',
  'MIT',
  'CDA',
  'CDTA',
];

export const SpoonColorsBra: string[] = [
  ...CommonSpoonColors,
  'NEGRO',
  'PLATEADA',
  'TRI',
  'JULI',
  'CHIF',
  'FAT',
  'PRÇ',
  'LASC',
  'MTDE',
  'SCHT',
  'SAL',
  'FOLH',
];

export interface ReplaceInReceiptsPayload {
  skus: string[];
  country: string;
  factor?: number;
  difference?: number;
  itemToChange: string;
  newItem: string;
  spoonColor?: string;
  kdsQuantity?: number;
}
