export enum commons {
  ACCEPT = 'commons.accept',
  ACTIONS = 'commons.actions',
  ADD = 'commons.add',
  BACK = 'commons.back',
  BRAND = 'commons.brand',
  CATEGORY = 'commons.categories',
  CANCEL = 'commons.cancel',
  CITY = 'commons.city',
  TIME = 'commons.time',
  CORRECT = 'commons.correct',
  CONTINUE = 'commons.continuar',
  COUNTRIES = 'commons.countries',
  COUNTRY = 'commons.country',
  COPY = 'commons.copy',
  DATETIME = 'commons.datetime',
  DELETE = 'commons.delete',
  DETAILS = 'commons.details',
  DOES_NOT_APPLY = 'commons.does_not_apply',
  EMPTY_RESULT = 'commons.empty_result',
  ERROR = 'commons.error',
  EXPORT = 'commons.export',
  FILTERS = 'commons.filters',
  FINAL_DATE = 'commons.finalDate',
  FINISH = 'commons.finish',
  FINISHED = 'commons.finished',
  FOR = 'commons.for',
  GROUP = 'commons.group',
  INIT_DATE = 'commons.initDate',
  ITEM = 'commons.item',
  IN = 'commons.in',
  KITCHENS = 'commons.kitchens',
  KITCHEN = 'commons.kitchen',
  LAST_MODIFIED = 'commons.last_modified',
  SELECT_BRAND = 'commons.select_brand',
  LAST_UPDATE = 'commons.last_update',
  DETAIL_OF_THE_REASON = 'commons.detail_of_the_reason',
  REASON = 'commons.reason',
  ENABLED = 'commons.activated',
  DISABLED = 'commons.disabled',
  LOADING = 'commons.loading',
  MENU = 'commons.menu',
  NAME = 'commons.name',
  MANDATORY = 'commons.mandatory',
  OF = 'commons.of',
  PROTOCOL = 'commons.protocol',
  PRODUCTS_TURNED_OFF = 'commons.products_turned_off',
  PHOTO = 'commons.PHOTO',
  PLATFORM = 'commons.platform',
  PLATFORMS = 'commons.platforms',
  PRODUCT_NAME = 'commons.product_name',
  PROGRESS = 'commons.progress',
  QUANTITY = 'commons.quantity',
  QUANTITY_IN_KDS = 'commons.quantity_in_kds',
  RECEIVE = 'commons.receive',
  RECIPE_BOOK = 'commons.recipe_book',
  RESULT_SEARCH = 'commons.result_search',
  RETURN = 'commons.return',
  SAVE = 'commons.save',
  SEARCH = 'commons.search',
  SELECT = 'commons.select',
  SELECT_ALL = 'commons.select_all',
  SELECT_CITY = 'commons.select_city',
  SELECT_COUNTRY = 'commons.select_country',
  SELECT_FILE = 'commons.select_file',
  SELECT_PAGE = 'commons.select_page',
  SELECT_PLATFORM = 'commons.select_platform',
  SKU = 'commons.sku',
  START = 'commons.start',
  STATUS = 'commons.status',
  SPECIFICATIONS = 'commons.specifications',
  TYPE = 'commons.type',
  UNIT = 'commons.unit',
  UNIT_IN_KDS = 'commons.unit_in_kds',
  UNITS = 'commons.units',
  WITHOUT_OPTIONS = 'commons.without_options',
  DISABLE = 'commons.disable',
  LOADING_PROTOCOLS = 'commons.loading_protocols',
  OFF = 'commons.off',
  ON = 'commons.on',
  PRODUCT = 'commons.product',
  SUPPLIES = 'commons.supplies',
  SUPPLY = 'commons.supply',
  PRODUCTS = 'commons.products',
  CURRENT_INVENTORY = 'commons.current_inventory',
  UNIT_OF_MEASUREMENT = 'commons.unit_of_measurement',
  PRODUCTION_PERFORMANCE = 'commons.production_performance',
  STATE = 'commons.state',
  STATION = 'commons.station',
  USEFUL_LIFE = 'commons.useful_life',
  ASSEMBLY = 'commons.assembly',
  INVENTORY_GROUP = 'commons.inventory_group',
  IVA_MAJOR_VALUE = 'commons.iva_major_value',
  TAX_GROUP_CODE = 'commons.tax_group_code',
  OVER_RECEIPT_CODE = 'commons.over_receipt_code',
  GROUPS_ACCOUNTING_RECORD_IVA = 'commons.groups_accounting_record_iva',
  STORAGE = 'commons.storage',
  STORES = 'commons.stores',
  STORE = 'commons.store',
  PRIORITY_STATUS = 'commons.priority_status',
  PRIORITY_TURN_OFF = 'commons.priority_turn_off',
  PRIORITY_TURN_ON = 'commons.priority_turn_on',
  NCM_CODE = 'commons.ncm_code',
  SHOW_AS_COMBO = 'commons.show_as_combo',
  SHOW_AS_TOPPING = 'commons.show_as_topping',
  IS_VARIABLE_COMBO = 'commons.is_variable_combo',
  HIDDEN = 'commons.hidden',
  RECIPE_IN_PRINTED_ORDER = 'commons.recipe_in_printed_order',
  IS_TOPPING = 'commons.is_topping',
  CATEGORIZATION = 'commons.categorization',
  EDIT = 'commons.edit',
  ASSIGN_MODIFIER = 'commons.assign_modifier',
  ADD_INSTRUCTIONS = 'commons.add_instructions',
  STEP = 'commons.step',
  CHECKPOINT = 'commons.checkpoint',
  UNITS_ABBREVIATION = 'commons.units_abbreviation',
  OTHERS = 'commons.others',
  WEIGHT = 'commons.weight',
  EXIT = 'commons.exit',
  YES = 'commons.YES',
  NO = 'commons.NO',
  CURRENT = 'commons.CURRENT',
  NEW = 'commons.NEW',
  DIFFERENCE = 'commons.difference',
  NEW_QUANTITY = 'commons.new.quantity',
  ULTRAPROCESSED_TAX = 'commons.ultra_processed_tax',
}

export enum recipeBookType {
  CREATE_NEW_ITEM = 'recipeBookType.create_new_item',
  DOWNLOAD_RECIPE = 'recipeBookType.download_recipe',
  PRODUCTS_HAS_CONTAINS = 'recipeBookType.products_has_contains',
  RECORD_CARD = 'recipeBookType.record_card',
  SEARCH_PRODUCTS = 'recipeBookType.search_products',
  TO_SEE_PRODUCTS = 'recipeBookType.to_see_product',
  ENABLE_ITEM = 'recipeBookType.enable_item',
  ENABLE = 'recipeBookType.enable',
  CAN_NOT = 'recipeBookType.can_not',
  CAN_NOT_DISABLE = 'recipeBookType.can_not_disable',
  CONFIRM_DISABLE = 'recipeBookType.confirm_disable',
  CONFIRM_DISABLE_TEXT = 'recipeBookType.confirm_disable_text',
  CAN_NOT_VIEW = 'recipeBookType.can_not_view',
  I_SURE = 'recipeBookType.i_sure',
  CONFIRM_ENABLE = 'recipeBookType.confirm_enable',
  CONFIRM_ENABLE_TEXT = 'recipeBookType.confirm_enable_text',
  CAN_VIEW = 'recipeBookType.can_view',
  CREATE_RECIPE = 'recipeBookType.create_view',
  MESSAGE_SELECT_COUNTRY_PRODUCT_TYPE = 'recipeBookType.message_select_country_product_type',
  INSTRUCTIONS_FOR_PREPARATION = 'recipeBookType.instructions_for_preparation',
  ADD_LIFE_CYCLE = 'recipeBookType.add_life_cycle',
  LIFE_CYCLE = 'recipeBookType.life_cycle',
  // STORAGE_TYPE = 'recipeBookType.storage_type',
  HAS_OPERATOR = 'recipeBookType.has_operator',
  MACHINE_REQUIRED = 'recipeBookType.machine_required',
  SELECT_MACHINE = 'recipeBookType.select_machine',
  STEP_MACHINE = 'recipeBookType.step_machine',
  DETAIL_CHECKPOINT = 'recipeBookType.detail_checkpoint',
  APPEARANCE = 'recipeBookType.appearance',
  COLOR = 'recipeBookType.color',
  SMELL = 'recipeBookType.smell',
  TASTE = 'recipeBookType.taste',
  TEXTURE = 'recipeBookType.texture',
  VISCOSITY = 'recipeBookType.viscosity',
  ENTER_CHARACTERISTICS_OF = 'recipeBookType.enter_characteristics_of',
  ENTER_THE = 'recipeBookType.enter_the',
  SENSORY_CHARACTERISTICS = 'recipeBookType.sensory_characteristics',
  UNITY_PER_PACKAGE = 'recipeBookType.unity_per_package',
  GENERAL = 'recipeBookType.general',
  UNITY_PER_WEIGHT = 'recipeBookType.unity_per_weight',
  PACKING_TEMPERATURE = 'recipeBookType.packing_temperature',
  PRODUCT_TEMPERATURE = 'recipeBookType.product_temperature',
  ENTER_PRODUCT_TEMPERATURE = 'recipeBookType.enter_product_temperature',
  PROTEIN = 'recipeBookType.protein',
  VINAIGRETTE = 'recipeBookType.vinaigrette',
  SAUCES = 'recipeBookType.sauces',
  GRAINS = 'recipeBookType.grains',
  BAKING = 'recipeBookType.baking',
  BASE = 'recipeBookType.base',
  DRINK = 'recipeBookType.drink',
  PREPARED_DRINK = 'recipeBookType.preparedDrink',
  COVERINGS = 'recipeBookType.coverings',
  DONATION = 'recipeBookType.donation',
  PACKAGING = 'recipeBookType.packaging',
  BAKERY = 'recipeBookType.bakery',
  MAIN_DISH = 'recipeBookType.mainDish',
  SIDES = 'recipeBookType.sides',
  DESSERTS = 'recipeBookType.desserts',
  SEASONAL_SAUCES = 'recipeBookType.seasonalSauces',
  TOPPING = 'recipeBookType.topping',
  BREADMAKING = 'recipeBookType.breadmaking',
  SUBPRODUCTION = 'recipeBookType.subproduction',
  HOT = 'recipeBookType.hot',
  FRYING = 'recipeBookType.frying',
  MIX = 'recipeBookType.mix',
  COLD = 'recipeBookType.cold',
  BAKERY_PASTRY_SHOP = 'recipeBookType.bakery_pastry_shop',
  STEP_DURATION = 'recipeBookType.step_duration',
  DURATION = 'recipeBookType.duration',
  CP_STATION = 'recipeBookType.cp_station',
  KITCHEN_STATION = 'recipeBookType.kitchen_station',
  VACUUM_PACKING = 'recipeBookType.vacuum_packing',
  STORAGE_TYPE = 'recipeBookType.storage_type',
  SHRINKAGE_PERCENTAGE = 'recipeBookType.shrinkage_percentage',
  PERCENTAGE = 'recipeBookType.percentage',
  ENTER_THE_PERCENTAGE_OF_SHRINKAGE_FOR_ALL_CITIES = 'recipeBookType.enter_the_percentage_of_shrinkage_for_all_cities',
  FACTOR = 'recipeBookType.factor',
  SELECT_SUPPLY = 'recipeBookType.select.supply',
  REPLACE_RECIPE_TITLE = 'recipeBookType.recipe.replace.title',
  SELECT_SUPPLY_MODAL_TITLE = 'recipeBookType.select.supply.modal.title',
  UPDATE_DIFFERENCE_MESSAGE = 'recipeBookType.update.difference.message',
  UPDATE_FACTOR_MESSAGE = 'recipeBookType.update.factor.message',
}

export enum menuType {
  DEFAULT_ERROR_MSG = 'menuType.default_error_msg',
  STARTED_LOADING_MENU = 'menuType.started_loading_menu',
  UPLOAD_MENU = 'menutype.upload_menu',
  UPLOAD_MENU_TO_VIEW = 'menuType.upload_menu_to_view',
}

export enum menuCreator {
  ADD_AISLE = 'menuCreator.add_aisle',
  PUBLISH_MENU = 'menuCreator.publish_menu',
  SAVE_DRAFT = 'menuCreator.save_draft',
  DRAFT = 'menuCreator.draft',
  SETTING = 'menuCreator.setting',
  MENU = 'menuCreator.menu',
  MENUS = 'menuCreator.menus',
  SEARCH_MENU = 'menuCreator.search_menu',
  CREATE_NEW_MENU = 'menuCreator.create_new_menu',
  MENU_NAME = 'menuCreator.menu_name',
  BRAND = 'menuCreator.brand',
  STORES = 'menuCreator.stores',
  LAST_UPDATE = 'menuCreator.last_update',
  ACTIONS = 'menuCreator.actions',
  BY = 'menuCreator.by',
  LIBRARY = 'menuCreator.library',
  SAVE_AISLE = 'menuCreator.save_aisle',
  ADD_MENU_ITEM = 'menuCreator.add_menu_item',
  AISLE_NAME = 'menuCreator.aisle_name',
  SAVE_MENU_ITEM = 'menuCreator.save_menu_item',
  SAVE_MODIFIER_TEMPLATE = 'menuCreator.save_modifier_template',
  ADD_MODIFIER_GROUP = 'menuCreator.add_modifier_group',
  PLATFORM_DESCRIPTION = 'menuCreator.platform_description',
  PLATFORM_NAME = 'menuCreator.platform_name',
  PRICE = 'menuCreator.price',
  RECIPE_BOOK_NAME = 'menuCreator.recipe_book_name',
  CHOOSE_IMAGE = 'menuCreator.choose_image',
  CREATE_MENU_MODAL_TITLE = 'menuCreator.create_menu_modal_title',
  COPY_MENU_MODAL_ACTION = 'menuCreator.copy_menu_modal_action',
  COPY_MENU_MODAL_LABEL = 'menuCreator.copy_menu_modal_label',
  COPY_MENU_MODAL_TITLE = 'menuCreator.copy_menu_modal_title',
  CREATE_MENU_MODAL_TEXT = 'menuCreator.create_menu_modal_text',
  CREATE_MENU_MODAL_NAME = 'menuCreator.create_menu_modal_name',
  CREATE_MENU_MODAL_CANCEL = 'menuCreator.create_menu_modal_cancel',
  CREATE_MENU_MODAL_ACTION = 'menuCreator.create_menu_modal_action',
  LOADING_COPY_MENU_TITLE = 'menuCreator.loading_copy_menu_title',
  LOADING_COPY_MENU_DETAIL = 'menuCreator.loading_copy_menu_detail',
  LOADING_COPY_MENU_BUTTON = 'menuCreator.loading_copy_menu_button',
  DELETE_MODAL_TITLE = 'menuCreator.delete_modal_title',
  DELETE_MODAL_TEXT = 'menuCreator.delete_modal_text',
  DELETE_MENU_ITEM_MODAL_TITLE = 'menuCreator.delete_menu_item_modal_title',
  DELETE_MENU_ITEM_MODAL_TEXT = 'menuCreator.delete_menu_item_modal_text',
  DELETE_MODAL_CANCEL = 'menuCreator.delete_modal_cancel',
  DELETE_MODAL_ACTION = 'menuCreator.delete_modal_action',
  MENU_MANAGER_TITLE = 'menuCreator.menu_manager_title',
  MENU_ITEMS = 'menuCreator.menu_items',
  ENTER_AISLE_NAME = 'menuCreator.enter_aisle_name',
  SEARCH_SKU_NAME = 'menuCreator.search_sku_name',
  ENTER_PLATFORM_NAME = 'menuCreator.enter_platform_name',
  ENTER_PRICE = 'menuCreator.enter_price',
  ENTER_DESCRIPTION = 'menuCreator.enter_description',
  THERE_IS_ALREADY_A_GROUP_WITH_THIS_NAME = 'menuCreator.there_is_already_a_group_with_this_name',
  THE_VALUE_CANNOT_BE_NEGATIVE = 'menuCreator.the_value_cannot_be_negative',
  MODIFIER_GROUP_NOT_FOUND = 'menuCreator.modifier_group_not_found',
  ENTER_A_NAME_FOR_THE_NEW_GROUP = 'menuCreator.enter_a_name_for_the_new_group',
  FILL_IN_THE_REQUIRED_DATA_IN_THE_MODIFIERS = 'menuCreator.fill_in_the_required_data_in_the_modifiers',
  A_PRODUCT_WITH_THIS_SKU_ALREADY_EXISTS_IN_THE_GROUP = 'menuCreator.a_product_with_this_sku_already_exists_in_the_group',
  MODIFIER_GROUP = 'menuCreator.modifier_group',
  DELETE_MODIFIER_GROUP = 'menuCreator.delete_modifier_group',
  MODIFIER_GROUP_NAME = 'menuCreator.modifier_group_name',
  ENTER_THE_GROUP_NAME = 'menuCreator.enter_the_group_name',
  MINIMUM = 'menuCreator.minimum',
  MAXIMUM = 'menuCreator.maximum',
  DELETE_MODIFIER = 'menuCreator.delete_modifier',
  OPTIONAL = 'menuCreator.optional',
  PREVIOUSLY_CREATED = 'menuCreator.previously_created',
  DRAG_MODIFIERS = 'menuCreator.drag_modifiers',
  CREATE_A_NEW_MODIFIER = 'menuCreator.create_a_new_modifier',
  ADD_MODIFIERS = 'menuCreator.add_modifiers',
  SAVE_GROUP_MODIFIERS = 'menuCreator.save_group_modifiers',
  EDIT_GROUP_MODIFIERS = 'menuCreator.edit_group_modifiers',
  MODIFIER_GROUPS = 'menuCreator.modifier_groups',
  DRAG_MODIFIERS_GROUPS = 'menuCreator.drag_modifiers_groups',
  CREATE_A_NEW_GROUP = 'menuCreator.create_a_new_group',
  ADD_MODIFIER_GROUPS = 'menuCreator.add_modifier_groups',
  ADD_THE_GROUPS = 'menuCreator.add_the_groups',
  SHOW_PUBLISHED = 'menuCreator.show_published',
  MODIFIERS = 'menuCreator.modifiers',
  ADD_THE_MODIFIERS = 'menuCreator.add_the_modifiers',
  DESCRIPTION = 'menuCreator.description',
  EDIT_MENU = 'menuCreator.edit_menu',
  VIEW_MENU = 'menuCreator.view_menu',
  SAVE_SETTINGS = 'menuCreator.save_settings',
  NONE_RESPONSE = 'menuCreator.none_response',
  SEND = 'menuCreator.send',
  REFUSED = 'menuCreator.refused',
  PUBLISHED = 'menuCreator.published',
  DATE_TEXT_DRAFT = 'menuCreator.date_text_draft',
  RESTORE_PUBLISHED = 'menuCreator.restore_published',
  RESTORE_MODAL_TITLE = 'menuCreator.restore_modal_title',
  RESTORE_MODAL_TEXT = 'menuCreator.restore_modal-text',
  MENU_WITH_CHANGES = 'menuCreator.menu_with_changes',
  REASSIGN_STORE = 'menuCreator.reassign_store',
  SCHEDULE = 'menuCreator.schedule',
  STORE_SCHEDULE = 'menuCreator.store_schedule',
  MONDAY = 'menuCreator.monday',
  TUESDAY = 'menuCreator.tuesday',
  WEDNESDAY = 'menuCreator.wednesday',
  THURSDAY = 'menuCreator.thursday',
  FRIDAY = 'menuCreator.friday',
  SATURDAY = 'menuCreator.saturday',
  SUNDAY = 'menuCreator.sunday',
  MORE_INFO_ONE = 'menuCreator.more_info_one',
  MORE_INFO_TWO = 'menuCreator.more_info_two',
  DELETE_MODIFIER_GROUPS = 'menuCreator.delete_modifier_groups',
  MODIFIER_GROUPS_SINGULAR = 'menuCreator.modifier_group_singular',
  CREATE_A_NEW_GROUP_OF_MODIFIERS = 'menuCreator.create_a_new_group_of_modifiers',
  EDIT_MODIFIER = 'menuCreator.edit_modifier',
  DELETE_MODIFIERS = 'menuCreator.delete_modifiers',
  SAVE_MODIFIER = 'menuCreator.save_modifier',
  ALERT_MAX_VALUE = 'menuCreator.alert_max_value',
  INTERNAL_NAME_MODIFIER_GROUP = 'menuCreator.internal_name_modifier_group',
  INTERNAL_NAME = 'menuCreator.internal_name',
  FIND_THE_MODIFIER_GROUP = 'menuCreator.find_the_modifier_group',
  WRITE_THE_NAME_OF_THE_GROUP = 'menuCreator.write_the_name_of_the_group',
  FIND_THE_MODIFIERS = 'menuCreator.find_the_modifiers',
  WRITE_THE_NAME_OF_THE_MODIFIER = 'menuCreator.write_the_name_of_the_modifier',
  EDIT_MENU_ITEM = 'menuCreator.edit_menu_item',
  EDIT_AISLE = 'meunCreator.edit_aisle',
  SELECTED = 'menuCreator.selected',
  ALERT_NO_CHILDREN = 'menuCreator.alert_no_childre',
  MAX = 'menuCreator.max',
  MIN = 'menuCreator.min',
  THERE_ARE_NO_RESULTS_FOR = 'menuCreator.there_are_no_results_for',
  CITY_ZONE = 'menuCreator.city_zone',
  SEARCH_COUNTRY = 'menuCreator.search_country',
  CHOOSE_COUNTRY = 'menuCreator.choose_country',
  STAY_IN_MENU = 'menuCreator.stay_in_menu',
  BACK_TO_BEGINNING = 'menuCreator.back_to_beginning',
  THE_MENU = 'menuCreator.the_menu',
  SAVED_MENU_TITLE = 'menuCreator.saved_menu_title',
  AFTER_PUBLISH_MENU_TITLE = 'menuCreator.after_publish_menu_title',
  SAVED_MENU_TEXT = 'menuCreator.saved_menu_text',
  PUBLISH_MENU_TITLE = 'menuCreator.publish_menu_title',
  PUBLISH_MENU_TEXT = 'menuCreator.publish_menu_text',
  ALL_GROUPS = 'menuCreator.all_groups',
  ALL_BRANDS = 'menuCreator.all_brands',
  ALL_CATEGORIES = 'menuCreator.all_categories',
  SELECT_COUNTRY = 'menuCreator.select_country',
  PRICE_CANNOT_BE_0 = 'menuCreator.price_cannot_be_0',
  CANNOT_REPEAT_A_MENU_ITEM = 'menuCreator.cannot_repeat_a_menu_item',
  EXCEEDED_THE_MAXIMUM_NUMBER_OF_CHARACTERS_FOR_RAPPI = 'menuCreator.exceeded_the_maximum_number_of_characters_for_rappi',
  MINIMUM_CAN_BE_3_CHARACTERS = 'menuCreator.minimum_can_be_3_characters',
  YOU_HAVE_EXCEEDED_THE_MAXIMUM_NUMBER_OF_CHARACTERS_ALLOWED = 'menuCreator.you_have_exceeded_the_maximum_number_of_characters_allowed',
  AND_YOU_ARE_AT = 'menuCreator.and_you_are_at',
  CHARACTERS_REACHING_IFOOD_LIMIT = 'menuCreator.characters_reaching_iFood_limit',
  MISSING = 'menuCreator.missing',
  CHARACTERS_TO_REACH_THE_MAXIMUM_ALLOWED = 'menuCreator.characters_to_reach_the_maximum_allowed',
  THERE_IS_ALREADY_A_MENU_WITH_THAT_NAME = 'menuCreator.there_is_already_a_menu_with_that_name',
  YOU_HAVE_NOT_SAVED_ALL_MODIFIER_GROUPS_IN_THE_MENU_ITEM = 'menuCreator.you_have_not_saved_all_modifier_groups_in_the_menu_item',
  YOU_HAVE_NOT_SAVED_ALL_THE_MENU_ITEMS = 'menuCreator.you_have_not_saved_all_the_menu_items',
  MENU_COPIED_SUCCESSFULLY = 'menuCreator.menu_copied_successfully',
  THIS_MENU_COULD_NOT_BE_ASSIGNED_TO_STORES = 'menuCreator.this_menu_could_not_be_assigned_to_stores',
  NUMBER_OF_CHARACTERS_DOES_NOT_REACH_THE_MINIMUM = 'menuCreator.number_of_characters_does_not_reach_the_minimum',
  NUMBER_OF_CHARACTERS_EXCEEDS_THE_MAXIMUM = 'menuCreator.number_of_characters_exceeds_the_maximum',
  IN_THE_HALLWAY_NAME_FIELD = 'menuCreator.in_the_hallway_name_field',
  IN_THE_MODIFIER_GROUP_NAME_FIELD = 'menuCreator.in_the_modifier_group_name_field',
  IN_THE_MENU_ITEM_DESCRIPTION = 'menuCreator.in_the_menu_item_description',
  IN_THE_MODIFIER_DESCRIPTION_FIELD = 'menuCreator.in_the_modifier_description_field',
  IN_THE_NAME_OF_THE_MENU_ITEM = 'menuCreator.in_the_name_of_the_menu_item',
  IN_THE_MODIFIER_NAME_FIELD = 'menuCreator.in_the_modifier_name_field',
  IN_THE_AISLE = 'menuCreator.in_the_aisle',
  THE_NUMBER_OF_DIGITS_IN_THE_PRICE_EXCEEDS_THE_MAXIMUM_ALLOWED = 'menuCreator.the_number_of_digits_in_the_price_exceeds_the_maximum_allowed',
  IN_THE_PRICE_FIELD_OF_THE_MENU_ITEM = 'menuCreator.in_the_price_field_of_the_menu_item',
  ALERT_VALUE_OF_THE_MODIFIERS = 'menuCreator.alert_value_of_the_modifiers',
  AISLE = 'menuCreator.aisle',
  PRODUCT = 'menuCreator.product',
  THERE_IS_ALREADY_A_HALLWAY_WITH_THAT_NAME = 'menuCreator.there_is_already_a_hallway_with_that_name',
  CANNOT_SAVE_MODIFIER_GROUP = 'menuCreator.cannot_save_modifier_group',
  CANNOT_SAVE_MODIFIER_GROUP_DETAIL = 'menuCreator.cannot_save_modifier_group_detail',
  SAVED_COMPONENT_FRAME = 'menuCreator.saved_component_frame',
  UPDATED_COMPONENT_FRAME = 'menuCreator.updated_component_frame',
  SAVED_MODIFIER_GROUP_DETAIL = 'menuCreator.saved_modifier_group_detail',
  CANNOT_SAVE_MODIFIER = 'menuCreator.cannot_save_modifier',
  CANNOT_SAVE_MODIFIER_DETAIL = 'menuCreator.cannot_save_modifier_detail',
  SAVED_MODIFIER_DETAIL = 'menuCreator.saved_modifier_detail',
  MINIMUM_VALUE_CANNOT_BE_GREATER_THAN_THE_MAXIMUM_VALUE = 'menuCreator.minimum_value_cannot_be_greater_than_the_maximum_value',
  RETURN_TO_EDIT = 'menuCreator.return_to_edit',
  SEE_LESS = 'menuCreator.see_less',
  SEE_MORE = 'menuCreator.see_more',
  SAVE_NAME = 'menuCreator.save.name',
  WRITE_THE_NAME_OF_THE_MENU_ITEM = 'menuCreator.write_the_name_of_the_menu_item',
  FIND_THE_MENU_ITEMS = 'menuCreator.find_the_menu_items',
  CREATE_A_MENU_ITEM = 'menuCreator.create_a_menu_item',
  YOU_CAN_NOW_CONSULT_THE_SAVED_MENU_ITEM_IN_THE_TEMPLATE_LIBRARY = 'menuCreator.you_can_now_consult_the_saved_menu_item_in_the_template_library',
  CANNOT_SAVE_THIS_MENU_ITEM = 'menuCreator.cannot_save_this_menu_item',
  TEMPLATE_FOR_THAT_MENU_ITEM_ALREADY_EXISTS = 'menuCreator.template_for_that_menu_item_already_exists',
  ADD_MENU_ITEMS = 'menuCreator.add_menu_items',
  DRAG_MENU_ITEMS = 'menuCreator.drag_menu_items',
  COMPLETE_MENU_ITEM = 'menuCreator.complete_menu_item',
  COLLAPSE_ALL_AISLES = 'menuCreator.collapse_all_aisles',
  EXPAND_ALL_AISLES = 'menuCreator.expand_all_aisles',
}

export enum categorization {
  CATEGORIZATION_BY_CITIES = 'categorization.categorization_by_cities',
}

export enum menuUploader {
  PERFECT_FILE = 'menuUploader.PERFECT_FILE',
  CLICK_TO_UPLOAD = 'menuUploader.CLICK_TO_UPLOAD',
  REVIEWING_MENU = 'menuUploader.REVIEWING_MENU',
}

export enum measureUnits {
  UN = 'measureUnits.un',
  G = 'measureUnits.g',
  ML = 'measureUnits.ml',
  GR = 'measureUnits.gr',
  KG = 'measureUnits.kg',
}

export enum productForm {
  DRAFT = 'productForm.draft',
  PUBLISHED = 'productForm.published',
  SAVE_DRAFT = 'productForm.save_draft',
  SAVE_CHANGES = 'productForm.save_changes',
  PUBLISH_CHANGES = 'productForm.publish_changes',
  CREATED = 'productForm.created',
  EDITED = 'productForm.edited',
  PUBLISH = 'productForm.publish',
  NCM_CODE_REQUIRED = 'productForm.ncm_code_required',
  COMPLETE_FORM = 'productForm.complete_form',
  AVOID_NEGATIVE_INVETORY = 'productForm.avoid_negative_inventory',
  SHOW_RECIPE_ON_STATION = 'productForm.show_recipe_on_station',
}

export enum timeUnit {
  // EMPTY = 'timeUnit.empty',
  HOUR = 'timeUnit.Hour',
  DAY = 'timeUnit.Day',
  WEEK = 'timeUnit.Week',
  // MONTH = 'timeUnit.month',
}

export enum storageUnit {
  // EMPTY = 'storageUnit.empty',
  FREEZING = 'storageUnit.Freezing',
  REFRIGERATION = 'storageUnit.Refrigeration',
  ROOM_TEMPERATURE = 'storageUnit.RoomTemperature',
}

export enum machineList {
  STOVE = 'machineList.Stove',
  UNOX = 'machineList.Unox',
  GRIDDLE = 'machineList.Griddle',
  DEEP_FRYER = 'machineList.DeepFryer',
  PIZZA_OVEN = 'machineList.PizzaOven',
  SEALER = 'machineList.Sealer',
  INDUSTRIAL_BLENDER = 'machineList.IndustrialBlender',
  MIXER = 'machineList.Mixer',
  DOUGH_MIXER = 'machineList.DoughMixer',
  IMMERSION_BLENDER = 'machineList.ImmersionBlender',
  ELECTRIC_MILL = 'machineList.ElectricMill',
  LANG_CINNABON_OVEN = 'machineList.LangCinnabonOven',
  COOKING_KETTLE = 'machineList.CookingKettle',
  FERMENTER = 'machineList.Fermenter',
  DOUGH_SHEETER = 'machineList.DoughSheeter',
  MIXER_60_LT = 'machineList.Mixer60Lt',
  BLAST_CHILLER = 'machineList.BlastChiller',
  COOLING = 'machineList.Cooling',
  MISE_EN_PLACE = 'machineList.MiseEnPlace',
  PROCESS = 'machineList.Process',
  PACKAGING = 'machineList.Packaging',
}

export enum info {
  BRAND = 'info.brand',
  CATEGORIZATION = 'info.categorization',
  EDITED = 'info.edited',
  HIDDEN = 'info.hidden',
  SAVED = 'info.saved',
  PUBLISH = 'info.publish',
  WARNING_PUBLISH = 'info.warning_publish',
  PRODUCT_TYPE = 'info.product_type',
  STATION = 'info.station',
  CATALOG = 'info.catalog',
  CATEGORY = 'info.category',
  HIDE_PRODUCT = 'info.hide_product',
  HIDE_TOPPING = 'info.hide_topping',
  LOADING_PRODUCTS = 'info.loading_products',
  LOADING_SUPPLIES = 'info.loading_supplies',
  SELECT_BRAND_AND_PLATFORM = 'info.select_brand_and_platform',
  PRODUCTS_NOT_FOUND = 'info.products_not_found',
  EMPTY_RECIPE = 'info.empty_recipe',
  INCORRECT_AMOUNT = 'info.incorrect_amount',
  ADD_AT_LEAST_ONE_INGREDIENT = 'info.add_at_least_one_ingredient',
  IS_REQUIRED = 'info.IS_REQUIRED',
  QUANTITY_LESS_THAN_OR_EQUAL_TO_0 = 'info.quantity_less_than_or_equal_to_0',
  ORGANOLEPTIC_MANDATORY = 'info.organoleptic_mandatory',
  SHELFLIFE_MANDATORY = 'info.shelflife_mandatory',
}

export enum productGroup {
  PRODUCTION = 'productGroup.production',
  FINISHED_PRODUCT = 'productGroup.finished_product',
  SUPPLY = 'productGroup.supply',
  COMBO = 'productGroup.combo',
  GROCERY = 'productGroup.grocery',
  DRINK = 'productGroup.drink',
  ALCOHOLIC_DRINK = 'productGroup.alcoholic_drink',
  MEAT = 'productGroup.meat',
  BEER = 'productGroup.beer',
  CIGARETTES = 'productGroup.cigarettes',
  UNKNOWN = 'productGroup.unknown',
  ADJUSTABLE_PACKAGING = 'productGroup.adjustable_packaging',
  PACKAGING = 'productGroup.packaging',
  FRUITS = 'productGroup.fruits',
  DAIRY = 'productGroup.dairy',
  MAINTENANCE = 'productGroup.maintance',
  BAKERY = 'productGroup.bakery',
  UTENSILS = 'productGroup.utensil',
}
export enum cpStationOptoins {
  HOT = 'cpStationOptoins.Hot',
  FRYING = 'cpStationOptoins.Frying',
  MIX = 'cpStationOptoins.Mix',
  COLD = 'cpStationOptoins.Cold',
  BAKERYPASTRY = 'cpStationOptoins.BakeryPastry',
  PACKAGING = 'cpStationOptoins.Packaging',
}

export enum productSearchOptions {
  ALL = 'productSearchOptions.ALL',
  RECIPE = 'productSearchOptions.RECIPE',
  COMBO = 'productSearchOptions.COMBO',
  FINISHED_PRODUCT = 'productSearchOptions.FINISHED_PRODUCT',
  PRODUCTION = 'productSearchOptions.PRODUCTION',
  SUPPLY = 'productSearchOptions.SUPPLY',
}

export type Locales = {
  [key in
    | commons
    | measureUnits
    | timeUnit
    | storageUnit
    | machineList
    | recipeBookType
    | menuType
    | menuCreator
    | categorization
    | productForm
    | menuUploader
    | info
    | productGroup
    | productSearchOptions
    | cpStationOptoins]: string;
};
