import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { MenuItemType, CreateMenuState, AisleData } from '../../../models/menu/menu';
import { ModifierGroupData } from '../../../models/menu/modifierGroup';

export const selectCreateMenuState = (state: RootState): CreateMenuState => state.createMenu;

const initialMenuItem: MenuItemType = {
  id: '',
  aisleId: '',
  priority: 1,
  modifiersViewOnly: false,
  menuItemViewOnly: false,
  productName: '',
  sku: '',
  image: '',
  price: 0,
  name: '',
  description: '',
  modifierGroups: [],
  saved: false,
};

const initialState: CreateMenuState = {
  name: '',
  id: '',
  existInstance: true,
  existDraft: false,
  isDraft: false,
  savedDraft: true,
  aisles: [
    {
      id: '',
      name: '',
      priority: 1,
      saved: false,
      menuItems: [initialMenuItem],
    },
  ],
  updatedAt: new Date().toString(),
};

const CreateMenuSlice = createSlice({
  name: 'create-menu-state',
  initialState,
  reducers: {
    setMenuState: (state, action: PayloadAction<{ menu: CreateMenuState }>) => {
      state.aisles = action.payload.menu.aisles;
      state.id = action.payload.menu.id;
      state.name = action.payload.menu.name;
      state.updatedAt = action.payload.menu.updatedAt;
    },
    setMenuNameState: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setMenuId: (state, action: PayloadAction<string>) => {
      state.id = action.payload;
    },
    setSaveMenu: (state, action: PayloadAction<boolean>) => {
      state.savedDraft = action.payload;
    },
    addAisle: (state, action: PayloadAction<{ id: number; name: string }>) => {
      state.aisles.push({
        id: action.payload.name,
        priority: action.payload.id + 1,
        name: action.payload.name,
        saved: true,
        menuItems: [
          {
            id: '0',
            aisleId: action.payload.name,
            priority: 1,
            modifiersViewOnly: false,
            menuItemViewOnly: false,
            productName: '',
            sku: '',
            image: '',
            price: 0,
            name: '',
            description: '',
            modifierGroups: [],
            saved: false,
          },
        ],
      });
    },
    removeAisle: (state, action: PayloadAction<string>) => {
      const { aisles } = state;
      const updatedAisles = aisles.filter((aisle) => aisle.id !== action.payload);
      state.aisles = updatedAisles;
    },
    saveMenuName: (state, action: PayloadAction<{ menuName: string }>) => {
      const { menuName } = action.payload;
      state.name = menuName;
    },
    saveAisleName: (state, action: PayloadAction<{ aisleId: string; aisleName: string }>) => {
      const { aisleId, aisleName } = action.payload;
      const aisleIndex = state.aisles.findIndex((aisle) => aisle.id === aisleId);

      if (state.aisles[aisleIndex]) {
        state.aisles[aisleIndex].name = aisleName;
        state.aisles[aisleIndex].id = aisleName;
      }
    },
    updateAisles: (state, action: PayloadAction<{ aisles: AisleData[] }>) => {
      const { aisles } = action.payload;
      state.aisles = aisles;
    },
    addMenuItem: (state, action: PayloadAction<{ aisleId: string; menuItem: MenuItemType }>) => {
      const { aisleId, menuItem } = action.payload;
      const aisleIndex = state.aisles.findIndex((aisle) => aisle.id === aisleId);

      if (aisleIndex !== -1) {
        state.aisles[aisleIndex].menuItems.push(menuItem);
      }
    },
    removeMenuItem: (state, action: PayloadAction<{ aisleId: string; menuItemId: string }>) => {
      const { aisleId, menuItemId } = action.payload;
      const aisleIndex = state.aisles.findIndex((aisle) => aisle.id === aisleId);
      if (aisleIndex !== -1) {
        const menuItemIndex = state.aisles[aisleIndex].menuItems.findIndex(
          (item) => item.id === menuItemId,
        );
        if (menuItemIndex !== -1) {
          const updatedMenuItems = [
            ...state.aisles[aisleIndex].menuItems.slice(0, menuItemIndex),
            ...state.aisles[aisleIndex].menuItems.slice(menuItemIndex + 1),
          ];
          return {
            ...state,
            aisles: [
              ...state.aisles.slice(0, aisleIndex),
              {
                ...state.aisles[aisleIndex],
                menuItems: updatedMenuItems,
              },
              ...state.aisles.slice(aisleIndex + 1),
            ],
          };
        }
      }
      return state;
    },
    updateMenuItem: (state, action: PayloadAction<{ aisleId: string; menuItem: MenuItemType }>) => {
      const { aisleId, menuItem } = action.payload;
      const aisleIndex = state.aisles.findIndex((aisle) => aisle.id === aisleId);
      const menuItemIndex = state.aisles[aisleIndex].menuItems.findIndex(
        (item) => item.id === menuItem.id,
      );
      if (aisleIndex !== -1 && menuItemIndex !== -1) {
        const existingModifierGroups =
          state.aisles[aisleIndex].menuItems[menuItemIndex].modifierGroups;
        state.aisles[aisleIndex].menuItems[menuItemIndex] = {
          ...state.aisles[aisleIndex].menuItems[menuItemIndex],
          id: menuItem.id,
          priority: menuItem.priority,
          modifiersViewOnly: menuItem.modifiersViewOnly,
          menuItemViewOnly: menuItem.menuItemViewOnly,
          productName: menuItem.productName,
          sku: menuItem.sku,
          image: menuItem.image,
          price: menuItem.price,
          name: menuItem.name,
          description: menuItem.description,
          modifierGroups: existingModifierGroups,
          saved: menuItem.saved,
        };
      }
    },
    updateMenuItems: (
      state,
      action: PayloadAction<{ aisleId: string; menuItems: MenuItemType[] }>,
    ) => {
      const { aisleId, menuItems } = action.payload;
      const aisleIndex = state.aisles.findIndex((aisle) => aisle.id === aisleId);

      if (aisleIndex !== -1) {
        state.aisles[aisleIndex].menuItems = menuItems;
      }
    },
    updateModifierGroups: (
      state,
      action: PayloadAction<{
        aisleId: string;
        menuItemId: string;
        modifierGroups: ModifierGroupData[];
      }>,
    ) => {
      const { aisleId, menuItemId, modifierGroups } = action.payload;
      const aisleIndex = state.aisles.findIndex((aisle) => aisle.id === aisleId);

      if (aisleIndex !== -1) {
        const menuItemIndex = state.aisles[aisleIndex].menuItems.findIndex(
          (item) => item.id === menuItemId,
        );

        if (menuItemIndex !== -1) {
          const formattedModifierGroups: ModifierGroupData[] = modifierGroups.map((group) => ({
            id: group.id,
            aisleId: aisleId,
            menuItemId: menuItemId,
            name: group.name,
            internalName: group.internalName,
            price: group.price,
            quantity: group.quantity,
            priority: group.priority,
            children: group.children,
          }));

          state.aisles[aisleIndex].menuItems[menuItemIndex].modifierGroups =
            formattedModifierGroups;
        }
      }
    },
    deleteModifierGroup: (
      state,
      action: PayloadAction<{ aisleId: string; menuItemId: string; modifierGroupId: string }>,
    ) => {
      const { aisleId, menuItemId, modifierGroupId } = action.payload;

      const aisleIndex = state.aisles.findIndex((aisle) => aisle.id === aisleId);

      if (aisleIndex !== -1) {
        const menuItemIndex = state.aisles[aisleIndex].menuItems.findIndex(
          (item) => item.id === menuItemId,
        );

        if (menuItemIndex !== -1) {
          state.aisles[aisleIndex].menuItems[menuItemIndex].modifierGroups = state.aisles[
            aisleIndex
          ].menuItems[menuItemIndex].modifierGroups.filter((group) => group.id !== modifierGroupId);
        }
      }
    },
    initializeEditMenuState: (state: CreateMenuState, action: PayloadAction<CreateMenuState>) => {
      state.name = action.payload.name;
      state.id = action.payload.id;
      state.existInstance = action.payload.existInstance;
      state.existDraft = action.payload.existDraft;
      state.isDraft = action.payload.isDraft;
      state.aisles = action.payload.aisles.map((aisle) => ({
        ...aisle,
        menuItems: aisle.menuItems.map((menuItem) => ({
          ...menuItem,
        })),
      }));
      state.updatedAt = action.payload.updatedAt;
    },
    resetState: () => {
      return initialState;
    },
  },
});

export const {
  setMenuState,
  setMenuNameState,
  setMenuId,
  setSaveMenu,
  addAisle,
  removeAisle,
  saveMenuName,
  saveAisleName,
  updateAisles,
  addMenuItem,
  removeMenuItem,
  updateMenuItem,
  updateMenuItems,
  updateModifierGroups,
  deleteModifierGroup,
  initializeEditMenuState,
  resetState,
} = CreateMenuSlice.actions;
export default CreateMenuSlice.reducer;
