import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Sidetab } from '@typeform/embed-react';
import dayjs from 'dayjs';

import { ENVIRONMENT, Env, NPS_SHOW_DAY, NPS_TYPEFORM_ID } from '../../config/app';
import Backdrop from '@mui/material/Backdrop';

interface SurveyModalProps {}

const isProduction = ENVIRONMENT === Env.PRODUCTION;
const showDay = NPS_SHOW_DAY;
const id = NPS_TYPEFORM_ID;

const SurveyModal: FunctionComponent<SurveyModalProps> = (props) => {
  const sidetabRef = useRef();

  const [showSurvey, setShowSurvey] = useState<boolean>(false);
  const [openOnPageLoad, setOpenOnPageLoad] = useState<boolean>(true);

  useEffect(() => {
    const lastAnswered = localStorage.getItem('lastAnswered');
    const lastShown = localStorage.getItem('lastShown');
    const answered = localStorage.getItem('answered') === 'true';

    if (!lastAnswered || dayjs(lastAnswered).isBefore(dayjs().startOf('month'))) {
      const shouldShowSurvey = dayjs().date() >= showDay && isProduction;
      setShowSurvey(shouldShowSurvey);
    }

    if (lastShown && !answered) {
      const shouldShowSurvey = dayjs().isAfter(dayjs(lastShown).add(20, 'minute')) && isProduction;
      setOpenOnPageLoad(shouldShowSurvey);
    }

    // Si el usuario omitió la encuesta, programamos para mostrarla de nuevo en 20 minutos
    let timeoutId: NodeJS.Timeout;
    if (lastShown && !answered) {
      const timeUntilNextShow = dayjs(lastShown).add(20, 'minute').diff(dayjs(), 'millisecond');
      timeoutId = setTimeout(() => setShowSurvey(true), timeUntilNextShow);
    }

    // Limpieza del useEffect
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  const onSurveyCompleted = () => {
    localStorage.setItem('lastAnswered', dayjs().toISOString());
    localStorage.setItem('answered', 'true');
    setShowSurvey(false);
  };

  const onSurveySkipped = () => {
    localStorage.setItem('lastShown', dayjs().toISOString());
    localStorage.setItem('answered', 'false');
    setOpenOnPageLoad(false);
  };

  return (
    <>
      {id !== null && showSurvey && (
        <Backdrop
          open={openOnPageLoad}
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Sidetab
            ref={sidetabRef}
            id={id}
            onSubmit={onSurveyCompleted}
            onClose={onSurveySkipped}
            open={openOnPageLoad ? 'load' : undefined}
            buttonText="Encuesta NPS"
            buttonColor="#011A5B"
          />
        </Backdrop>
      )}
    </>
  );
};

export default SurveyModal;
