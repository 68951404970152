import axios from 'axios';
import { getReasonValue } from '../core/OnAndOff/utils/product.util';
import { Classification } from '../models/classification';
import { NCMCode } from '../models/ncm-code';
import {
  FinishedProduct,
  Product,
  ProductManager,
  ProductQuery,
  ProductQueryResponse,
  ProductSearch,
  ProductStore,
  ProductionDetailPayload,
  ProductsSupply,
  ReplaceInReceiptsPayload,
  StoreProduct,
  TaskCode,
  TaskState,
} from '../models/product';
import { SearchByKitchenResponse, SearcherPayload, SearcherResponse } from '../models/searcher';
import { Supply } from '../models/supply';
import { TheoreticalInventory } from '../models/theoreticalInventory';

const getPreparationBySkuAndCountry = (country: string, sku: string) => {
  const url = `${
    process.env.REACT_APP_AWS_BUCKECT_URL
  }/${country.toLowerCase()}/preparation/${sku}.txt`;
  return axios({
    method: 'GET',
    url,
  }).then<string>((response) => response.data);
};
const getTotalRecords = async (
  isAdmin: boolean,
  country: string,
): Promise<{ totalRecords: number }> => {
  let data = await axios({
    method: 'post',
    url: `${process.env.REACT_APP_RATATOUILLE_URL}/api/v1/product/countRecordsByCountry${
      isAdmin ? '/admin' : ''
    }`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: country,
  }).then<number>((response) => {
    return response.data[country];
  });
  const totalRecords = data || 0;
  return { totalRecords };
};

const findAll = async (
  isAdmin: boolean,
  country: string,
  page = 0,
  resultsPerPage = 10,
): Promise<{ totalRecords: number; products: Product[] }> => {
  const skip = resultsPerPage * page;
  const limit = skip + resultsPerPage;
  const { totalRecords } = await getTotalRecords(isAdmin, country);
  const products = await getProducts(isAdmin, country, limit, skip);
  return { totalRecords, products };
};

const getProducts = async (
  isAdmin: boolean,
  country: string,
  limit: number,
  skip: number,
): Promise<any> => {
  const products = await axios({
    method: 'post',
    url: `${process.env.REACT_APP_RATATOUILLE_URL}/api/v1/product/searchAllProducts${
      isAdmin ? '/admin' : ''
    }`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      country: country,
      limit: limit,
      skip: skip,
    },
  }).then((response) => {
    return response.data;
  });

  return products;
};

const getTotalResults = async (
  isAdmin: boolean,
  country: string,
  inputText: string,
): Promise<{ totalResults: number }> => {
  let data = await axios({
    method: 'post',
    url: `${process.env.REACT_APP_RATATOUILLE_URL}/api/v1/product/getTotalResults${
      isAdmin ? '/admin' : ''
    }`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      country: country,
      name: inputText,
    },
  }).then<number>((response) => {
    return response.data[country];
  });
  const totalResults = data || 0;
  return { totalResults };
};

const search = async (
  isAdmin: boolean,
  country: string,
  inputText: string,
  page = 0,
  resultsPerPage = 10,
  filterGroup?: Array<string>,
): Promise<{ totalResults: number; products: Product[] }> => {
  if (!inputText) {
    const response = await findAll(isAdmin, country, page, resultsPerPage);
    return { totalResults: response.totalRecords, ...response };
  }
  const { totalResults } = await getTotalResults(isAdmin, country, inputText);
  const skip = resultsPerPage * page;
  const limit = skip + resultsPerPage;
  const products = await searchProducts(isAdmin, inputText, country, limit, skip, filterGroup).then(
    (products) => {
      return products.map((product) => {
        return { ...product, published: getPublished(product) };
      });
    },
  );
  return { totalResults, products };
};

const searchProducts = async (
  isAdmin: boolean,
  inputText: string,
  country: string,
  limit: number,
  skip: number,
  group?: Array<string>,
) => {
  const filterGroup = group || [];
  const products = await axios({
    method: 'post',
    url: `${process.env.REACT_APP_RATATOUILLE_URL}/api/v1/product/query${isAdmin ? '/admin' : ''}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      name: inputText,
      country: country,
      limit: limit,
      skip: skip,
      group: filterGroup,
    },
  }).then<Product[]>((response) => {
    return response.data;
  });

  return products;
};

const getPublished = (product: Product) => {
  const tasks = product.tasks ?? [];
  const published = !tasks.find(
    (task) => task.code === TaskCode.POST_IN_BC && task.state !== TaskState.DONE,
  );
  return published;
};

const findBySkuAndCountry = async (country: string, sku: string) => {
  const product = await axios
    .get<Product>(
      `${process.env.REACT_APP_RATATOUILLE_URL}/api/v1/product/find/${sku}/none/${country}`,
    )
    .then((res) => {
      return { ...res.data, published: getPublished(res.data) };
    });
  return product;
};

const create = async (product: Product) => {
  await axios.post(`${process.env.REACT_APP_RATATOUILLE_URL}/api/v1/product/insert`, product, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

const replaceInRecipes = async (payload: ReplaceInReceiptsPayload) => {
  await axios.post(
    `${process.env.REACT_APP_RATATOUILLE_URL}/api/v1/product/update-in-recipes`,
    payload,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
};

const updateProductionDetail = async (productionDetail: ProductionDetailPayload) => {
  await axios.post(
    `${process.env.REACT_APP_RATATOUILLE_URL}/api/v1/product/updateProductionDetail`,
    productionDetail,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
};

const findProductionDetailBySkuAndCountry = async (sku: string, country: string) => {
  const product = await axios
    .get<Product>(
      `${process.env.REACT_APP_RATATOUILLE_URL}/api/v1/product/productionDetail/${sku}/${country}`,
    )
    .then((res) => {
      return { ...res.data };
    });
  return product;
};

const updateBySku = async (product: Product, shouldPostChanges: boolean) => {
  const payload = JSON.stringify({
    product: { ...product, lastUpdatedAt: new Date() },
    postChanges: shouldPostChanges,
  });
  await axios.post(`${process.env.REACT_APP_RATATOUILLE_URL}/api/v1/product/update`, payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

const updateMany = async (products: Array<Product>) => {
  const payload = JSON.stringify({
    products: products.map((product) => ({ ...product, lastUpdatedAt: new Date() })),
  });
  await axios.post(`${process.env.REACT_APP_RATATOUILLE_URL}/api/v1/product/updateMany`, payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

const validateNameAndSkuByCountry = async (product: Product): Promise<boolean> => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_RATATOUILLE_URL}/api/v1/product/find/${product.sku}/${product.name}/${product.country}`,
  );
  return data === null;
};

const validateName = async (product: Product): Promise<boolean> => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_RATATOUILLE_URL}/api/v1/product/check/${product.sku}/${product.name}/${product.country}`,
  );
  return data === null || data.sku === product.sku;
};

const findLastSku = async (country: string) => {
  const { data } = await axios.get<string>(
    `${process.env.REACT_APP_RATATOUILLE_URL}/api/v1/product/last/sku/${country}`,
  );
  return data;
};

const findByKitchen = async (
  kitchenId: string,
  page = 0,
  resultsPerPage = 200,
): Promise<ProductManager[]> => {
  const url = `${process.env.REACT_APP_API_URL}/api/v1/menu/manager/kitchen/${kitchenId}/products?page=${page}&resultsPerPage=${resultsPerPage}`;
  return axios({
    method: 'GET',
    url,
  }).then<ProductManager[]>(async (response) => {
    const classification = await getClassificationByKitchenId(kitchenId);
    const enrichedProducts = response.data.map((product: ProductManager) => {
      const inventaryProduct = classification.find(
        (item: Classification) => item.sku === product.sku,
      );
      return inventaryProduct ? { ...product, category: inventaryProduct.classification } : product;
    });
    return enrichedProducts;
  });
};

const updateSingleProduct = async (product: ProductManager): Promise<ProductManager> => {
  const url = `${process.env.REACT_APP_API_URL}/api/v1/menu/manager/product/availability`;
  let status: string | null = getReasonValue(product);
  axios({
    method: 'POST',
    url,
    headers: {
      'Content-Type': 'application/json',
    },
    data: JSON.stringify({
      kitchenId: product.kitchenId,
      sku: product.sku,
      availability: status,
      user: product.user,
    }),
  })
    .then<string>((response) => response.data)
    .catch(console.error);
  return product;
};

const searchProductByKitchen = async (
  kitchenId: string,
  page = 0,
  resultsPerPage = 5000,
): Promise<ProductSearch[]> => {
  const url = `${process.env.REACT_APP_API_URL}/api/v1/menu/manager/kitchen/${kitchenId}/products_lite?page=${page}&resultsPerPage=${resultsPerPage}`;
  return axios({
    method: 'GET',
    url,
  }).then<ProductSearch[]>((response) => response.data);
};

const getClassificationByKitchenId = async (kitchenId: string): Promise<Classification[]> => {
  const url = `${process.env.REACT_APP_RATATOUILLE_URL}/api/v1/product/classification/${kitchenId}`;
  try {
    return await axios({
      method: 'GET',
      url,
    }).then<Classification[]>((response) => response.data);
  } catch (error) {
    console.error('Error fetching classification by kitchenId:', error);
    return [];
  }
};

const getStoresByKitchenAndSku = async (kitchenId: string, sku: string): Promise<StoreProduct> => {
  const url = `${process.env.REACT_APP_API_URL}/api/v1/menu/manager/stores/by_product/${kitchenId}/${sku}`;
  return axios({
    method: 'GET',
    url,
  }).then<StoreProduct>((response) => response.data);
};
const getProductsByKitchenAndSku = async (
  kitchenId: string,
  sku: string,
): Promise<ProductsSupply> => {
  const url = `${process.env.REACT_APP_API_URL}/api/v1/menu/manager/supply/${sku}/${kitchenId}/products`;
  return axios({
    method: 'GET',
    url,
  }).then<ProductsSupply>((response) => ({
    kitchenId,
    products: response.data,
  }));
};

const getClassification = async (sku: string, country: string): Promise<Classification[]> => {
  const url = `${process.env.REACT_APP_RATATOUILLE_URL}/api/v1/product/classification/${sku}/${country}`;
  try {
    return await axios({
      method: 'GET',
      url,
    }).then<Classification[]>((response) => response.data);
  } catch (error) {
    console.error('Error fetching classification:', error);
    throw error;
  }
};

const getTheoreticalInventory = async (kitchenId: string): Promise<TheoreticalInventory[]> => {
  const url = `${process.env.REACT_APP_KITCHEN_INVENTORY_CORE_URL}/api/v1/theoretical-inventory/${kitchenId}`;
  try {
    return await axios({
      method: 'GET',
      url,
    }).then<TheoreticalInventory[]>((response) => response.data);
  } catch (error) {
    console.error('Error fetching theoretical inventory:', error);
    return [];
  }
};

const searchNCMCode = async (textToSearch: string) => {
  const url = `${process.env.REACT_APP_RATATOUILLE_URL}/api/v1/product/ncm-code/search?textToSearch=${textToSearch}`;
  return await axios.get(url).then<NCMCode[]>((response) => response.data);
};

const completeTask = async (productId: string, taskCode: TaskCode) => {
  const url = `${process.env.REACT_APP_RATATOUILLE_URL}/api/v1/product/complete-task`;
  const payload = { productId, taskCode };
  return await axios.post(url, payload);
};

const getProductsByCountryAndIngredient = async (
  country: string,
  ingredientSku: string,
): Promise<Array<Product>> => {
  const url = `${process.env.REACT_APP_RATATOUILLE_URL}/api/v1/product/${country}/ingredient/${ingredientSku}`;

  try {
    const products = await axios.get<Array<Product>>(url);

    return products.data;
  } catch (error) {
    console.error(`Error getting getProductsByCountryAndIngredient:`, error);
    throw error;
  }
};

const searcher = async (search: SearcherPayload): Promise<SearcherResponse[]> => {
  const url = `${process.env.REACT_APP_API_URL}/api/v1/product/manager/metaData`;
  try {
    return await axios({
      method: 'POST',
      url,
      data: {
        country: search.country,
        query: search.query,
        key: 'name',
        limit: 400,
        kitchenId: search.kitchenId,
      },
    }).then<SearcherResponse[]>((response) => response.data);
  } catch (error) {
    console.error('Error searching product:', error);
    throw error;
  }
};

const getRecipesByCountry = async (country: string): Promise<Blob> => {
  const url = `${process.env.REACT_APP_RATATOUILLE_URL}/api/v1/product/recipes/${country}`;
  try {
    const response = await axios.get(url, { responseType: 'blob' });
    return new Blob([response.data], { type: 'text/csv' });
  } catch (error) {
    console.error('Error getting prescriptions by country:', error);
    throw error;
  }
};

const enrichSupplies = async (
  supplies: Supply[],
  kitchenId: string,
  classification: Classification[],
  theoreticalInventory: TheoreticalInventory[],
): Promise<Supply[]> => {
  const enrichedSupplies = supplies.map((supply) => {
    const inventaryProduct = classification.find((item) => item.sku === supply.sku);
    const theoreticalInventoryData = theoreticalInventory.find(
      (inventoryItem) => inventoryItem.sku === supply.sku,
    );

    return inventaryProduct
      ? {
          ...supply,
          category: inventaryProduct.classification,
          theoreticalInventory: theoreticalInventoryData
            ? theoreticalInventoryData.quantity + ' ' + theoreticalInventoryData.measureUnit
            : '',
        }
      : supply;
  });

  return enrichedSupplies;
};

const enrichProducts = async (
  products: ProductManager[],
  classification: Classification[],
): Promise<ProductManager[]> => {
  const enrichedProducts = products.map((product) => {
    const inventaryProduct = classification.find((item) => item.sku === product.sku);
    return inventaryProduct ? { ...product, category: inventaryProduct.classification } : product;
  });

  return enrichedProducts;
};

const isNumber = (term: string): boolean => {
  return /^\d+$/.test(term);
};

type RelevanceScore = {
  frequency: number;
  position: number;
  length: number;
};

const calculateRelevance = (
  item: { name: string; sku: string },
  searchTerm: string,
  useSKU: boolean,
): RelevanceScore => {
  const text = useSKU ? item.sku : item.name;
  const regex = new RegExp(searchTerm, 'gi');

  let matches = [];
  let match;
  while ((match = regex.exec(text)) !== null) {
    matches.push(match);
  }

  const position = text.toLowerCase().indexOf(searchTerm.toLowerCase());

  return {
    frequency: matches.length,
    position: position,
    length: text.length,
  };
};

const sortByRelevance = (items: any[], searchTerm: string, useSKUForRelevance: boolean) => {
  return items.sort((a, b) => {
    const relevanceA = calculateRelevance(a, searchTerm, useSKUForRelevance);
    const relevanceB = calculateRelevance(b, searchTerm, useSKUForRelevance);

    if (relevanceA.frequency !== relevanceB.frequency) {
      return relevanceB.frequency - relevanceA.frequency;
    } else if (relevanceA.position !== relevanceB.position) {
      return relevanceA.position - relevanceB.position;
    } else {
      return relevanceA.length - relevanceB.length;
    }
  });
};

const searchByKitchen = async (
  kitchenId: string,
  searchTerm: string,
): Promise<SearchByKitchenResponse> => {
  const url = `${process.env.REACT_APP_API_URL}/api/v1/menu/manager/${kitchenId}/search?q=${searchTerm}`;

  const [classification, theoreticalInventory] = await Promise.all([
    getClassificationByKitchenId(kitchenId),
    getTheoreticalInventory(kitchenId),
  ]);

  const useSKUForRelevance = isNumber(searchTerm);

  try {
    const response = await axios.get<SearchByKitchenResponse>(url);

    const enrichedData: SearchByKitchenResponse = {
      ...response.data,
      supplies: await enrichSupplies(
        response.data.supplies,
        kitchenId,
        classification,
        theoreticalInventory,
      ),
      products: await enrichProducts(response.data.products, classification),
    };

    enrichedData.products = sortByRelevance(enrichedData.products, searchTerm, useSKUForRelevance);
    enrichedData.supplies = sortByRelevance(enrichedData.supplies, searchTerm, useSKUForRelevance);

    return enrichedData;
  } catch (error) {
    console.error(`Error searching ${searchTerm} on ${kitchenId}:`, error);
    throw error;
  }
};

const getProductStorebyCountryAndSku = async (sku: string, country: string) => {
  try {
    const productStore = await axios.get<ProductStore>(
      `${process.env.REACT_APP_API_URL}/api/v1/menu/manager/${country}/product/${sku}/store`,
    );

    return productStore.data;
  } catch (error) {
    console.error(`Error getting getProductStorebyCountryAndSku:`, error);
    throw error;
  }
};

export type ProductQueryParams = {
  country: string;
  disabled?: boolean;
  name?: string;
  skip?: number;
  limit?: number;
  categories?: string;
  groups?: string;
  brands?: string;
  isCombo?: boolean;
  type?: string;
};

export type ProductResponse = {
  items: Array<Product>;
  total: number;
  skip: number;
  limit: number;
  filterOptions: {
    brand: Array<string>;
    category: Array<string>;
    group: Array<string>;
  };
};

const getAllProducts = async (params: ProductQueryParams): Promise<ProductResponse> => {
  try {
    const productStore = await axios.get<ProductResponse>(
      `${process.env.REACT_APP_RATATOUILLE_URL}/api/v1/product`,
      { params },
    );

    return productStore.data;
  } catch (error) {
    console.error(`Error getting getAllProducts:`, error);
    throw error;
  }
};

const getProductsFinishedProducts = async (query: ProductQuery): Promise<ProductQueryResponse> => {
  try {
    const response = await axios.post<FinishedProduct[]>(
      `${process.env.REACT_APP_RATATOUILLE_URL}/api/v1/product/query`,
      query,
      {
        headers: { 'Content-Type': 'application/json' },
      },
    );
    const products: FinishedProduct[] = response.data.map(({ sku, name, country, category }) => ({
      sku,
      name,
      country,
      category,
    }));
    return { products };
  } catch (error) {
    console.error(`Error querying products:`, error);
    throw error;
  }
};

const ProductService = {
  getProductsFinishedProducts,
  completeTask,
  getPreparationBySkuAndCountry,
  search,
  findBySkuAndCountry,
  create,
  updateProductionDetail,
  findProductionDetailBySkuAndCountry,
  validateNameAndSku: validateNameAndSkuByCountry,
  updateBySku,
  validateName,
  findLastSku,
  findByKitchen,
  updateSingleProduct,
  searchProductByKitchen,
  getStoresByKitchenAndSku,
  getProductsByKitchenAndSku,
  getClassification,
  searchNCMCode,
  searcher,
  getClassificationByKitchenId,
  getTheoreticalInventory,
  getRecipesByCountry,
  searchByKitchen,
  enrichSupplies,
  enrichProducts,
  getProductStorebyCountryAndSku,
  getProductsByCountryAndIngredient,
  updateMany,
  getAllProducts,
  replaceInRecipes,
};

export default ProductService;
