import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { OnAndOffState } from './types';
import { AsyncState } from '../../types';
import {
  getProductsState,
  getProtocolsState,
  getStoresBySkuAndKitchen,
  getProductsBySkuAndKitchen,
  getSuppliesState,
  searchProductByKitchen,
  updateProductSingle,
  updateProtocolSingle,
  updateSupplySingle,
} from './OnAndOffStateThunk';
import { Supply } from '../../../models/supply';
import { ProductManager, ProductsSupply, StoreProduct } from '../../../models/product';
import { Protocol } from '../../../models/protocol';

const initialState: OnAndOffState = {
  products: {
    data: [],
    status: AsyncState.IDLE,
  },
  suplies: {
    data: [],
    status: AsyncState.IDLE,
    dataDrawer: null,
    openDrawer: null,
    statusDataDrawer: AsyncState.IDLE,
  },
  protocols: {
    data: [],
    checkedSkus: [],
    status: AsyncState.IDLE,
    productsPopup: [],
    statusProductPopup: AsyncState.IDLE,
    newRow: false,
    statusSaving: AsyncState.IDLE,
    dataDrawer: null,
    statusDataDrawer: AsyncState.IDLE,
    countDrawer: 0,
    openDrawer: false,
  },
  kitchenId: null,
  editable: false,
};
const OnAndOffSlice = createSlice({
  name: 'on-and-off-state',
  initialState,
  reducers: {
    setEditable: (state, action) => {
      state.editable = action.payload;
    },
    setSkuProtocol: (state, action) => {
      state.protocols.checkedSkus = action.payload;
    },
    addSkuProtocol: (state, action) => {
      state.protocols.checkedSkus.push(action.payload);
    },
    removeSkuProtocol: (state, action) => {
      state.protocols.checkedSkus = state.protocols.checkedSkus.filter(
        (sku) => sku !== action.payload,
      );
    },
    toggleNewRowProtocol: (state, action: PayloadAction<boolean>) => {
      state.protocols.newRow = action.payload;
      state.protocols.statusSaving = AsyncState.IDLE;
    },
    setKitchen: (state, action: PayloadAction<string | null>) => {
      state.kitchenId = action.payload;
    },
    toggleOpenDrawerProduct: (state, action: PayloadAction<boolean>) => {
      state.protocols.openDrawer = action.payload;
      state.protocols.dataDrawer = null;
    },
    toggleOpenDrawerSupply: (state, action: PayloadAction<Supply | null>) => {
      state.suplies.openDrawer = action.payload;
      state.suplies.dataDrawer = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProductsState.pending, (state, action) => {
      state.products.status = AsyncState.LOADING;
    });
    builder.addCase(getProductsState.rejected, (state, action) => {
      state.products.status = AsyncState.ERROR;
    });
    builder.addCase(getProductsState.fulfilled, (state, action) => {
      state.products.status = AsyncState.SUCCESS;
      state.products.data = action.payload;
    });
    builder.addCase(getSuppliesState.pending, (state, action) => {
      state.suplies.status = AsyncState.LOADING;
    });

    builder.addCase(getSuppliesState.rejected, (state, action) => {
      state.suplies.status = AsyncState.ERROR;
    });
    builder.addCase(getSuppliesState.fulfilled, (state, action) => {
      state.suplies.status = AsyncState.SUCCESS;
      state.suplies.data = action.payload;
    });

    builder.addCase(getProtocolsState.pending, (state, action) => {
      state.protocols.status = AsyncState.LOADING;
    });
    builder.addCase(getProtocolsState.rejected, (state, action) => {
      state.protocols.status = AsyncState.ERROR;
    });
    builder.addCase(getProtocolsState.fulfilled, (state, action) => {
      state.protocols.status = AsyncState.SUCCESS;
      state.protocols.data = action.payload;
    });

    builder.addCase(updateSupplySingle.fulfilled, (state, action: PayloadAction<Supply>) => {
      const index = state.suplies.data.findIndex((element) => element.sku === action.payload.sku);
      if (index !== -1) {
        state.suplies.data[index] = action.payload;
      }
    });
    builder.addCase(
      updateProductSingle.fulfilled,
      (state, action: PayloadAction<ProductManager>) => {
        const index = state.products.data.findIndex(
          (element) => element.sku === action.payload.sku,
        );
        if (index !== -1) {
          state.products.data[index] = action.payload;
        }
      },
    );

    builder.addCase(updateProtocolSingle.fulfilled, (state, action: PayloadAction<Protocol>) => {
      const index = state.protocols.data.findIndex(
        (element) => element.name === action.payload.name,
      );
      if (index !== -1) {
        state.protocols.data[index] = action.payload;
      }
      state.protocols.checkedSkus = [];
      state.protocols.statusSaving = AsyncState.SUCCESS;
    });

    builder.addCase(updateProtocolSingle.pending, (state, action) => {
      state.protocols.statusSaving = AsyncState.LOADING;
    });
    builder.addCase(updateProtocolSingle.rejected, (state, action) => {
      state.protocols.statusSaving = AsyncState.ERROR;
    });

    builder.addCase(searchProductByKitchen.pending, (state, action) => {
      state.protocols.statusProductPopup = AsyncState.LOADING;
    });
    builder.addCase(searchProductByKitchen.rejected, (state, action) => {
      state.protocols.statusProductPopup = AsyncState.ERROR;
    });
    builder.addCase(searchProductByKitchen.fulfilled, (state, action) => {
      state.protocols.statusProductPopup = AsyncState.SUCCESS;
      state.protocols.productsPopup = action.payload;
    });

    builder.addCase(getStoresBySkuAndKitchen.pending, (state, action) => {
      state.protocols.statusDataDrawer = AsyncState.LOADING;
    });
    builder.addCase(getStoresBySkuAndKitchen.rejected, (state, action) => {
      state.protocols.statusDataDrawer = AsyncState.ERROR;
    });

    builder.addCase(
      getStoresBySkuAndKitchen.fulfilled,
      (state, action: PayloadAction<StoreProduct>) => {
        state.protocols.statusDataDrawer = AsyncState.SUCCESS;
        state.protocols.dataDrawer = action.payload;
      },
    );

    builder.addCase(getProductsBySkuAndKitchen.pending, (state, action) => {
      state.suplies.statusDataDrawer = AsyncState.LOADING;
    });
    builder.addCase(getProductsBySkuAndKitchen.rejected, (state, action) => {
      state.suplies.statusDataDrawer = AsyncState.ERROR;
    });

    builder.addCase(
      getProductsBySkuAndKitchen.fulfilled,
      (state, action: PayloadAction<ProductsSupply>) => {
        state.suplies.statusDataDrawer = AsyncState.SUCCESS;
        state.suplies.dataDrawer = action.payload;
      },
    );
  },
});

export const {
  addSkuProtocol,
  removeSkuProtocol,
  setSkuProtocol,
  toggleNewRowProtocol,
  setKitchen,
  toggleOpenDrawerProduct,
  toggleOpenDrawerSupply,
  setEditable,
} = OnAndOffSlice.actions;
export default OnAndOffSlice.reducer;
