import axios from 'axios';
import { Supply } from '../models/supply';
import { StatusAvailability } from '../models/types';
import ProductService from './product.service';
import { Classification } from '../models/classification';
import { Response, SupplyDetail } from '../models/supplyDetails';

const findByKitchen = async (
  kitchenId: string,
  page = 0,
  resultsPerPage = 200,
): Promise<Supply[]> => {
  const url = `${process.env.REACT_APP_API_URL}/api/v1/menu/manager/kitchen/${kitchenId}/supplies?page=${page}&resultsPerPage=${resultsPerPage}`;
  return axios({
    method: 'GET',
    url,
  }).then<Supply[]>(async (response) => {
    const classification = await ProductService.getClassificationByKitchenId(kitchenId);
    const enrichedProducts = response.data.map((supply: Supply) => {
      const inventaryProduct = classification.find(
        (item: Classification) => item.sku === supply.sku,
      );
      return inventaryProduct ? { ...supply, category: inventaryProduct.classification } : supply;
    });
    return enrichedProducts;
  });
};

const updateSingleSupply = async (supply: Supply): Promise<Supply> => {
  const url = `${process.env.REACT_APP_API_URL}/api/v1/menu/manager/supply/availability`;
  axios({
    method: 'POST',
    url,
    headers: {
      'Content-Type': 'application/json',
    },
    data: JSON.stringify({
      kitchenId: supply.kitchenId,
      sku: supply.sku,
      availability: supply.available
        ? StatusAvailability.Available
        : StatusAvailability.Unavailable,
      user: supply.user,
    }),
  }).then<string>((response) => response.data);
  return supply;
};

const findDiscarded = async (id: string): Promise<SupplyDetail | null> => {
  try {
    const response = await axios.get<Response>(
      `${process.env.REACT_APP_RATATOUILLE_URL}/api/v1/supply/details/${id}`,
    );

    return response.data.data;
  } catch (error: any) {
    console.error('Error getting data:', error);
    return null;
  }
};

const upsertDiscarded = async (data: SupplyDetail): Promise<SupplyDetail | null> => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_RATATOUILLE_URL}/api/v1/supply/details/upsert`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error('Error updating data', error);
    return null;
  }
};

const SupplyService = {
  findByKitchen,
  updateSingleSupply,
  upsertDiscarded,
  findDiscarded,
};

export default SupplyService;
