import { MenuItem } from '@foodology-co/alejandria';
import Session from '../../../routes/components/Session/models/session';

const getMenuForCurrentUser = async (session: Session): Promise<MenuItem[]> => {
  const request = await fetch(
    `${process.env.REACT_APP_KITCHEN_DISPLAY_URL}/api/menuByUser/menu-manager`,
    {
      method: 'GET',
      headers: new Headers({
        'x-user-id': session.userId,
        'x-auth-token': session.loginToken,
      }),
    },
  );

  if (!request.ok) {
    console.error('Errro getting user for menu');
    return [];
  }

  const menu = await request.json();
  return menu;
};

const MenuService = {
  getMenuForCurrentUser,
};

export default MenuService;
