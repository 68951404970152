export default () => {
  const scriptUrl = process.env.REACT_APP_ENV === "production" ?
    'https://main.d6jxuk7doph17.amplifyapp.com/static/js/widget-tt.js':
    'https://develop.d6jxuk7doph17.amplifyapp.com/static/js/widget-tt.js';

  const script = document.createElement('script');
  script.src = scriptUrl;
  script.async = true;

  document.head.appendChild(script);
};